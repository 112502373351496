<h1>{{ 'buy-ticket.generate-ticket' | translate:{ gameName: currentGameGroup.name } }}</h1>

<button class="close-btn" (click)="closeCouponPicker()"></button>

<p>
	<ng-container *ngIf="gameBoards.length === 1">
		{{ (allowComboBets ? 'buy-ticket.choose-numbers-one-board-combo' : 'buy-ticket.choose-numbers-one-board') | translate: { 'numbersTot': boardNumsToSelect[0], 'maxNumbersTot': maxBoardNumsToSelect[0] } }}
	</ng-container>
	<ng-container *ngIf="gameBoards.length > 1">
		{{ (allowComboBets ? 'buy-ticket.choose-numbers-two-boards-combo' : 'buy-ticket.choose-numbers-two-boards') | translate: { 'boardOneNumbersTot': boardNumsToSelect[0], 'boardTwoNumbersTot': boardNumsToSelect[1] } }}
	</ng-container>
</p>

<hg-coupon-picker #couponPicker>
	<hg-board #board *ngFor="let b of gameBoards; let i = index;"
		boardType="number"
		[boardData]="b"
		[boardMinNumber]="b.minNumber"
		[boardMaxNumber]="b.maxNumber"
		[allowCombos]="allowComboBets"
		(itemPick)="pickNumber()"
	>
		<ng-template let-number>
			<span class="number" [class.is-picked]="number.isPicked" [class.dark]="i % 2 !== 0" [class.combo-bet]="number.isComboBetPick">
				{{ number.number }}
			</span>
		</ng-template>

	</hg-board>
</hg-coupon-picker>

<p>
	<hg-dropdown [list]="consecutiveDrawsItems" [listSelection]="consecutiveDraws" (listSelectionChange)="consecutiveDrawsChange($event)">
		<ng-template #selectedItemTemplate>
			<span>
				{{ 'draw-selector.values.draws' | translate:{ 'drawsAmount': consecutiveDraws[0].numOfDraws } }}
			</span>
		</ng-template>
		<ng-template #itemsTemplate let-item>
			<span>
				{{ 'draw-selector.values.draws' | translate:{ 'drawsAmount': item['numOfDraws'] }  }}
			</span>
		</ng-template>
	</hg-dropdown>

	<button class="default-btn" (click)="randomPick()">
		{{ 'coupon-picker.random-btn' | translate }}
	</button>
	<button class="default-btn" (click)="clearSelection()">
		{{ 'coupon-picker.clear-selection-btn' | translate }}
	</button>

	<hg-dropdown [list]="games" [listSelection]="selectedGames" (listSelectionChange)="selectedGameChange($event)" *ngIf="allowMultiBets">
		<ng-template #selectedItemTemplate>
			<span>
				{{selectedGames[0].tier}}
			</span>
		</ng-template>
		<ng-template #itemsTemplate let-item>
			<span>
				{{item.tier}}
			</span>
		</ng-template>
	</hg-dropdown>

	<button #scrollToElement class="default-btn" (click)="addSelection()" [disabled]="!minItemsSelected">
		{{ 'coupon-picker.add-selection-btn' | translate }}
	</button>
</p>

