import {Injectable} from '@angular/core';
import {TenantRetailCapability} from '../models/capability.model';

@Injectable({
	providedIn: 'root'
})
export class CapabilitiesService {

	private _capabilities: Map<string, object> = new Map<string, object>();

	constructor() {
	}

	setCapabilities = (capabilities: TenantRetailCapability[]) => {

		capabilities.forEach(c => {
			const v = this.parseType(c.value, c.type);
			this._capabilities.set(c.name, v);
		});

		//  console.log(`Agent capabilities: ${capabilities.map(c => `${c.name}:${c.value}`).join(',')}`)
	}

	getCapability = (name: string): any => {
		return this._capabilities.get(name);
	}

	private parseType = (value: string, type: string): any => {
		switch (type) {
			case 'bool': {
				return value === 'true';
			}

			case 'int': {
				return parseInt(value, 10);
			}

			case 'string': {
				return value;
			}
		}
	}
}
