import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';

import { environment } from '../../environments/environment';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
	handleError(error: Error) {
		// log to application insights
		if (environment.appInsightsKey !== undefined) {
			AppInsights.trackException(error);
		}

		// log to console
		console.error(error);
	}
}
