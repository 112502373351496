<div *ngIf="!isMobile">
	<button class="default-btn" (click)="scanTicket()">{{ 'app-header.scan-ticket-btn' | translate }}</button>

	<span class="check-ticket-input">
		<span class="separator">|</span>

		<input type="text" [(ngModel)]="couponID" [placeholder]="'app-header.check-ticket-txt' | translate"
			   (keydown.enter)="checkTicket(couponID)">

		<button class="default-btn" [class.is-loading]="isCheckTicketLoading" (click)="checkTicket(couponID)"
				[disabled]="couponID === '' || couponID === undefined">
			<span>{{ 'app-header.check-ticket-btn' | translate }}</span>
		</button>
	</span>
</div>

<div *ngIf="isMobile">
	<button class="default-btn"
			(click)="toggleCheckTicketMenu()">{{ 'app-header.check-ticket-btn-short' | translate }}
	</button>

	<div class="mobile-ticket-menu" *ngIf="checkMobileTicketMenu">
		<button class="default-btn" (click)="scanTicket()">{{ 'app-header.scan-ticket-btn-short' | translate }}</button>
		<div class="ticket-number-container">
			<input type="text" [(ngModel)]="couponID" [placeholder]="'app-header.check-ticket-txt' | translate"
				   (keydown.enter)="checkTicket(couponID)">
			<button class="default-btn" [class.is-loading]="isCheckTicketLoading" (click)="checkTicket(couponID)"
					[disabled]="couponID === '' || couponID === undefined">
				<span>{{ 'app-header.check-ticket-btn-short' | translate }}</span>
			</button>
		</div>
	</div>

</div>

