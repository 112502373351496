import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import * as dayjs from 'dayjs';
import * as _utc from 'dayjs/plugin/utc';

import { StatsFilter } from './stats-filter.model';
import { AppLangService } from '../app-header';
import { AuthService } from '../shared/services';
import { SalesPrintData, SalesPrintDataItem } from '../shared/models/sales-print-data.model';
import { AgentStat } from 'helio-games-core';

@Component({
	selector: 'ra-sales-stats',
	templateUrl: './sales-stats.component.html',
	styleUrls: ['./sales-stats.component.scss']
})
export class SalesStatsComponent implements OnInit {

	filters: StatsFilter[] = [];
	currentFilter: StatsFilter;

	blockRefreshFilters = false;

	localeID: string;
	printing: boolean = false;
	isPrinting: boolean = false;

	currentSalesStats: AgentStat[] = [];

	@ViewChild('printSalesIframeDiv', { static: false }) printSalesIframeDiv: ElementRef;
	private iframeElement: HTMLIFrameElement;

	constructor(
		private translateService: TranslateService,
		private appLangService: AppLangService,
		private authService: AuthService
	) { }

	ngOnInit() {
		this.localeID = this.appLangService.selectedLocaleID;

		if (dayjs.utc === undefined) {
			dayjs.extend(_utc);
		}

		this.setFilters();
	}

	refreshFilters() {
		this.currentSalesStats = [];

		if (!this.blockRefreshFilters) {
			this.blockRefreshFilters = true;
			this.setFilters(true);

			const timeout = setTimeout(() => {
				this.blockRefreshFilters = false;
				clearTimeout(timeout);
			}, 3000);
		}
	}

	filterClickHandler(filter: StatsFilter) {
		this.currentFilter = Object.assign({}, filter);
	}

	private setFilters(isRefreshFilters = false) {
		this.currentSalesStats = [];
		const now = dayjs(new Date());
		this.filters = [
			{
				name: this.translateService.instant('sales-stats.filters.today'),
				dateFrom: now.hour(0)
					.minute(0)
					.second(0)
					.millisecond(0)
					.toDate(),
				dateTo: new Date()
			},
			{
				name: this.translateService.instant('sales-stats.filters.yesterday'),
				dateFrom: now.subtract(1, 'day').startOf('day').toDate(),
				dateTo: now.subtract(1, 'day').endOf('day').toDate()
			},
			{
				name: this.translateService.instant('sales-stats.filters.this-week'),
				dateFrom: now.startOf('week').toDate(),
				dateTo: new Date()
			},
			{
				name: this.translateService.instant('sales-stats.filters.last-week'),
				dateFrom: now.subtract(1, 'week').startOf('week').toDate(),
				dateTo: now.subtract(1, 'week').endOf('week').toDate()
			},
			{
				name: this.translateService.instant('sales-stats.filters.this-month'),
				dateFrom: now.startOf('month').toDate(),
				dateTo: new Date()
			},
			{
				name: this.translateService.instant('sales-stats.filters.last-month'),
				dateFrom: now.subtract(1, 'month').startOf('month').toDate(),
				dateTo: now.subtract(1, 'month').endOf('month').toDate()
			},
			{
				name: this.translateService.instant('sales-stats.filters.last-3-months'),
				dateFrom: now.subtract(3, 'month').startOf('month').toDate(),
				dateTo: now.subtract(1, 'month').endOf('month').toDate()
			}
		];

		if (!isRefreshFilters) {
			this.currentFilter = this.filters[0];
		} else {
			this.currentFilter = this.filters.find(f => f.name === this.currentFilter.name);
		}
	}

	onSalesStatsReceived = (stats: AgentStat[]) => {
		this.currentSalesStats = stats;
	}

	print() {
		if (!this.isPrinting){
			if (!this.authService.isLoggedIn && !this.authService.canRefreshToken) {
				this.authService.logout();
			}			
			this.isPrinting = true;
			this.createPrintIframe();

			this.checkIframeLoadedandPrint();	
		}	
	}

	private checkIframeLoadedandPrint() {
		// Get a handle to the iframe element
		var iframe = this.iframeElement;
		var hasLoadedData:boolean = false;
		
		// In this section we are searching for the price element to be loaded in the iFrame
		if (this.iframeElement.contentWindow.document.children.length > 0)
			hasLoadedData = this.iframeElement.contentWindow.document.children[0].getElementsByClassName('ending-balance').length > 0;
		
		// Check if loading is complete
		if (  hasLoadedData ) {
			//a timeout is added so that if some elements in Angular are still not loaded, then wait 1 second for them to load
			setTimeout(() => {
				this.isPrinting = false;
				this.iframeElement.contentWindow.print();
			}, 1000);
			
			// If it has loaded return so that status is not checked again
			return;
		} 
	
		// If we are here, it is not loaded. Set things up so we check  the status again in 100 milliseconds
		window.setTimeout(()=>{ this.checkIframeLoadedandPrint(); }, 100);
	}

	private createPrintIframe() {

		const items: SalesPrintDataItem[] = this.currentSalesStats.map((s: AgentStat) => {
			return {
				game: s.gameName,
				sold: s.totalSales,
				paid: s.totalPayouts,
				cancelled: s.totalCancelled
			} as SalesPrintDataItem
		});

		let salesInfo = {
			filterName: this.currentFilter.name,
			from: this.currentFilter.dateFrom,
			to: this.currentFilter.dateTo,
			timestamp: new Date(),
			items: items
		} as SalesPrintData;

		const ticketData = btoa(JSON.stringify(salesInfo));
			const iframeTarget: HTMLElement = this.printSalesIframeDiv.nativeElement;
	
			while (iframeTarget.firstChild) {
				iframeTarget.removeChild(iframeTarget.firstChild);
			}

			let iframe = document.createElement('iframe');	
				
	
			iframe.id = 'print-sales-content';
			iframe.src = `${window.location.origin}/#/print-sales-stats/${ticketData}`;
			iframe.style.position = 'absolute';
			iframe.style.opacity = '0';
			iframe.style.visibility = 'hidden';
	
			this.iframeElement = iframeTarget.appendChild(iframe);		
	}

}
