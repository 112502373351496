import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../services';

@Injectable()
export class SecureContentGuard implements CanActivate {

	constructor(
		private authService: AuthService
	) { }

	canActivate(): Observable<boolean> {
		if (!this.authService.isLoggedIn && !this.authService.canRefreshToken) {
			this.authService.logout();

			return of(false);
		}

		return of(true);
	}

}
