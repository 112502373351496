<h1>{{ 'printer-setup.title' | translate }}</h1>

<p>
	{{ 'printer-setup.page-width-lbl' | translate }}
	<hg-dropdown
		[list]="dropdownItems"
		[listSelection]="selectedItem"
		(listSelectionChange)="dropdownChange($event)"
		[labelProp]="'pageWidthMM'">
	</hg-dropdown>
	<br>
	<i class="note">{{ 'printer-setup.page-width-note' | translate }}</i>
</p>
<hr>
<p>
	<button class="default-btn" (click)="testPrint()">
		{{ 'printer-setup.test-print-btn' | translate }}
	</button>
</p>
