export interface DrawDropdownItem {
	numOfDraws: number;
	itemName: string;
}

export const DRAW_DROPDOWN_ITEMS: DrawDropdownItem[] = [
	{
		numOfDraws: 1,
		itemName: '1 Draw'
	},
	{
		numOfDraws: 2,
		itemName: '2 Draws'
	},
	{
		numOfDraws: 3,
		itemName: '3 Draws'
	},
	{
		numOfDraws: 4,
		itemName: '4 Draws'
	},
	{
		numOfDraws: 5,
		itemName: '5 Draws'
	}
];
