import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'ra-no-content',
	templateUrl: './no-content.component.html',
	styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
