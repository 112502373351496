import {Injectable} from '@angular/core';
import {
	AppConfigService,
	BaseService,
	Dictionary,
	ResponseBodyType, ServiceHeadersUtility,
	ServiceRequestBodyUtility, ServiceUrlsUtility
} from 'helio-games-core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {ServiceController} from '../service-controller.urls';

@Injectable({
	providedIn: 'root'
})
export class RetailAgentService extends BaseService {
	constructor(protected http: HttpClient,
				private appConfigService: AppConfigService,
				private authService: AuthService) {

		super(http, ServiceController.RETAIL_AGENT, retailApiService(appConfigService.serviceBaseURL));
	}

	getActualBalance(): Observable<number> {
		const authHeaderValue = `${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`;

		const dict = new Dictionary<string>({'Authorization': authHeaderValue});

		return this.get('', [], undefined, {
				headersValues: dict, appendToDefaultHeaders: true
			}
		).pipe(map(agent => {
			return agent.balance;
		}));
	}

	public purchaseAndPrint<T>(postData?: any, searchParams?: HttpParams): Observable<any> {
		const controller = ServiceController.TRANSACTION;

		// ServiceAction.PLACE_BET is not exports as part of helio-games-core and throws error if used!
		const serviceUrl = ServiceUrlsUtility.getUrl(this.baseServiceURL, controller, 'placebet');

		const contentType = 'application/json';

		const body = ServiceRequestBodyUtility.getRequestBody(postData, contentType);

		const token = JSON.parse(localStorage.getItem('appToken'));

		const options: any = {
			headers: {
				'Authorization': token['token_type'] + ' ' + token['access_token'],
				'Content-Type': contentType
			}
		};

		if (searchParams !== undefined) {
			options.params = searchParams;
		}

		// @ts-ignore
		options.responseType = this.setResponseType(ResponseBodyType.JSON);

		return this.http.post<T>(serviceUrl, body, options)
	}
}

/**
 * Temp solution to inject authServiceBaseURL based on applicable environment.
 * TODO - update the declaration in helio-lottery-games-core where contents of dev-ts1.json, for e.g., is being used
 * @summary needs to be a static function and not part of the class so that it can be called before super.
 */
export function retailApiService(serviceBaseURL: string) {
	const retailApiServiceURL = (env: string) => {
		return `https://retail-api.helioengine-${env}.com/`;
	};

	if (serviceBaseURL.includes('dev-ts1')) {
		return retailApiServiceURL('dev-ts1');
	} else if (serviceBaseURL.includes('stg-ts1')) {
		return retailApiServiceURL('stg-ts1');
	} else if (serviceBaseURL.includes('helioengine-ts2')) {
		return retailApiServiceURL('ts2');
	} else if (serviceBaseURL.includes('helioengine-qa-ts1')) {
		return retailApiServiceURL('qa-ts1');
	} else {
		throw new Error(`retailApiService is not configured for the current env: ${environment.env}`);
	}
}
