<h1>{{ 'sales-stats.title' | translate }}</h1>

<div class="page-btns">
	<button [ngClass]="{ 'default-btn': !isPrinting , 'secondary-btn': isPrinting }" [disabled]="currentSalesStats.length === 0 || isPrinting" (click)="print()">
		{{ 'purchase-print.print-btn' | translate }}
	</button>
	<button class="default-btn" (click)="refreshFilters()" [disabled]="blockRefreshFilters">
		{{ 'sales-stats.refresh-data-btn' | translate }}
	</button>
</div>

<ng-container *ngIf="filters.length > 0">
	<div class="filters-wrapper">
		<div class="filters">
			<button *ngFor="let filter of filters"
				class="filter-btn"
				(click)="filterClickHandler(filter)"
				[disabled]="filter.name === currentFilter.name"
			>
				{{ filter.name }}
			</button>
		</div>
	</div>

	<p>
		<span [innerHTML]="'sales-stats.stats-subtitle' | translate: { filterName: currentFilter.name } | hgSafeHtml"></span>
		<span class="separator">&nbsp;|&nbsp;</span>
		<br>
		<i>{{ currentFilter.dateFrom | date:'medium':undefined:localeID }} - {{ currentFilter.dateTo | date:'medium':undefined:localeID }}</i>
	</p>

	<ra-stats-list [dateFrom]="currentFilter.dateFrom" [dateTo]="currentFilter.dateTo" (onStatsReceived)="onSalesStatsReceived($event)"></ra-stats-list>
</ng-container>
<div #printSalesIframeDiv></div>