import { environment } from '../../../environments/environment';

export const getGameServer = (clusterID: string) => {
	// console.log('getGameServer: clusterID =', clusterID, 'environment.env =', environment.env);

	switch (environment.env) {
		case 'prod':
			return `prod-${clusterID}`;
		case 'stage':
			return `stg-${clusterID}`;
		case 'qa':
			return `qa`;
		case 'dev-server':
			return `dev-server`;
		case 'dev':
		default:
			throw new Error('Unsupported environment for gameServer');
	}
};
