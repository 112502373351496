<h1>{{ 'draw-stats.title' | translate }}</h1>
<h2>{{ gameName }}</h2>

<div class="table-wrapper">
	<ngx-datatable class="material"
		[rows]="data"
		[loadingIndicator]="isTableLoading"
		[columnMode]="'force'"
		[footerHeight]="50"
		rowHeight="auto"
		[reorderable]="false"
		[externalPaging]="true"
		[count]="totalRows"
		[offset]="pageNumber"
		[limit]="pageSize"
		(page)="setPage($event)"
	>
		<ngx-datatable-column [sortable]="false" [resizeable]="false" [name]="'game-draw-stats.table.draw-num' | translate">
			<ng-template let-row="row" ngx-datatable-cell-template>
				{{ row['drawNumber'] }}
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-column [sortable]="false" [resizeable]="false" [name]="'game-draw-stats.table.draw-date' | translate">
			<ng-template let-row="row" ngx-datatable-cell-template>
				{{ row['drawDate'] | date:'medium':undefined:localeID }}
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-column [sortable]="false" [resizeable]="false" [name]="'game-draw-stats.table.draw-result' | translate">
			<ng-template let-row="row" ngx-datatable-cell-template>
				{{ row['results'] | raFormatDrawResult }}
			</ng-template>
		</ngx-datatable-column>
	</ngx-datatable>
</div>

<label>
	<span>Display: </span>
<hg-dropdown
	[labelProp]="'size'"
	[list]="pageSizes"
	[listSelection]="selectedSize"
	(listSelectionChange)="changePageSize($event)">	
</hg-dropdown>
</label>

<button class="default-btn print-btn" (click)="print()">
	{{ 'purchase-print.print-btn' | translate }}
</button>

<div #printDrawStatsIframeDiv></div>