<div class="header">
	<!-- <h1 [class.print-page]="isPrintPage">{{ purchasedTicket.tenantName }}</h1> -->
	<div *ngIf="hasTenantLogo" class="tenant-logo-wrapper">
		<div class="tenant-logo" [style.backgroundImage]="tenantLogo"></div>
	</div>
	<div class="add-margin agent-details" style="display: grid; justify-content: center;">
		<div>Agent: {{ agentFullName }}</div>

		<!--Setting the know with of 80mm, using 76mm as worked better, we can use this know scale
		to determine what the height of the printed ticket should be.-->
		<span class="address" style="display: block; width: 80mm">{{ agentAddress }}</span>
	</div>
	<div class="comment" *ngIf="couponToPrint !== undefined && couponToPrint.tickets[0].comment !== undefined">
		Customer: {{ couponToPrint.tickets[0].comment }}
	</div>

	<div class="game-logo">
		<!-- <div class="logo {{ purchasedTicket.gameGroupCode }}"></div> -->
		<h2>{{ couponToPrint.tickets[0].gameName }}</h2>
	</div>
</div>

<ng-container *ngIf="couponToPrint !== undefined">
	<div class="content">
		<div *ngFor="let ticket of lotteryTickets; let i = index" class="lottery-lines">
			<div class="board-nums" *ngFor="let ticketBoards of ticket.selectedNumbers; let j = index">
				<span class="line-number">{{ (65 + i + j) | numberToChar }}.</span>
				<ng-container *ngFor="let boardNumbers of ticketBoards; let k = index">
					<span class="board-separator" *ngIf="k > 0">+</span>
					<ng-container *ngFor="let number of boardNumbers">
						<span class="number" [class.print-page]="isPrintPage">
							{{ number }}
						</span>
					</ng-container>
				</ng-container>
				<small class="tier-label" [class.print-page]="isPrintPage"
					   *ngIf="!ticket.isDefaultGame">{{ ticket.gameTier }}</small>
			</div>
		</div>

		<div *ngIf="raffleTickets && raffleTickets.length > 0" style="padding: 8px 0 10px;">
			<h2>Raffle numbers</h2>

			<div *ngFor="let ticket of raffleTickets; let i = index" class="raffle-lines">
				<ng-container *ngFor="let ticketBoards of ticket.selectedNumbers; let j = index">
					<span class="raffle-number" [class.print-page]="isPrintPage">
						{{ ticketBoards }}
					</span>
				</ng-container>
			</div>
		</div>

		<div class="info add-margin">
			<div class="price add-margin">
				<b>{{ 'purchase-print.price-lbl' | translate }}</b> {{
					totalCost |
						currency:couponToPrint.tickets[0].currencyCode:'symbol':'1.0-2':localeID |
						hgReplaceCurrencyCode:couponToPrint.tickets[0].currencyCode
				}}
			</div>
			<div>
				<b>{{ 'purchase-print.first-draw-lbl' | translate }}</b> {{
					couponToPrint.tickets[0].firstDrawDate |
						date:'short':'1.0-2':localeID
				}}
			</div>
			<div>
				<b>{{ 'purchase-print.ticket-validity-lbl' | translate }}</b>
				{{
					'draw-selector.values.draws' | translate: {'drawsAmount': couponToPrint.tickets[0].ticketValidForDraws}
				}}
			</div>
			<ng-container *ngIf="this.couponToPrint.tickets[0].drawsFromTo.length === 1">
				<div>
					<b>{{ 'purchase-print.draw-num-lbl' | translate }}</b> {{ couponToPrint.tickets[0].drawsFromTo[0] }}
				</div>
			</ng-container>
			<ng-container *ngIf="this.couponToPrint.tickets[0].drawsFromTo.length === 2">
				<div
					[innerHTML]="'purchase-print.from-to-draw' | translate: { 'firstDrawNum': couponToPrint.tickets[0].drawsFromTo[0], 'lastDrawNum': couponToPrint.tickets[0].drawsFromTo[1] } | hgSafeHtml">
				</div>
			</ng-container>
			<!-- <div>
				<b>{{ 'purchase-print.agent-lbl' | translate }}</b> {{ purchasedTicket.agentID }}
			</div> -->
			<div class="add-margin">
				{{ couponToPrint.tickets[0].timestamp | date:'medium':'1.0-2':localeID }}
			</div>

			<div class="max-win">
				{{ 'purchase-print.max-win-lbl' | translate }} {{
					couponToPrint.maxWin |
						currency:couponToPrint.tickets[0].currencyCode:'symbol':'1.0-2':localeID |
						hgReplaceCurrencyCode:couponToPrint.tickets[0].currencyCode
				}}
			</div>

			<div class="reprints" *ngIf="reprints > 0">
				{{ 'purchase-print.reprint' | translate }} #: {{ reprints }}
			</div>

			<div *ngIf="qrCodeDataURL" class="qr-code add-margin"
				 style="display: flex; justify-content: center; height: 150px; width: 100%">
				<!--<qr-code [value]="couponToPrint.tickets[0].couponIdentifier" [size]="150"
						 [errorCorrectionLevel]="'H'"></qr-code>-->

				<img [src]="qrCodeDataURL" alt="QR Code" style="object-fit: contain"/>
			</div>

			<div>
				{{ couponToPrint.tickets[0].friendlyCouponIdentifier }}
			</div>

			<div *ngIf="termsAndConditions">
				<div dir="auto" class="ticket-terms-and-conditions" [innerHTML]="termsAndConditions"></div>
			</div>
		</div>
	</div>
	<!-- <div class="page-break"></div> -->
</ng-container>
