import { Component, OnInit } from '@angular/core';
import { AgentDrawHistoryService, AgentPastDraws } from 'helio-games-core';
import { Observable } from 'rxjs';
import { AppLangService } from 'src/app/app-header';
import { Game } from 'src/app/shared/models/game';
import { AuthService, GamesService } from '../../shared/services';

@Component({
	selector: 'ra-draw-stats-list',
	templateUrl: './draw-stats-list.component.html',
	styleUrls: ['./draw-stats-list.component.scss']
})
export class DrawStatsListComponent implements OnInit {
	availableGames: Game[];
	gamesLastDraw: AgentPastDraws[] = [];
	isLoading = false;

	getDataError = false;

	constructor(
		private agentDrawHistoryService: AgentDrawHistoryService,
		private authService: AuthService,
		private appLangaugeService: AppLangService,
		private gamesService: GamesService,
	) { }

	ngOnInit() {
		const currencyCode = this.authService.authTokenData.payload.CurrencyCode;
		const languageCode = this.appLangaugeService.selectedLanguage;
		this.gamesService.getGames(currencyCode, languageCode).subscribe(games => {
			this.availableGames = games;
			this.getPastDraw();
		});
	}

	private getPastDraw() {
		this.isLoading = true;
		const authToken = `${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`;

		this.agentDrawHistoryService.getPastDraw(authToken)
			.subscribe(res => {
				let games = res.games.filter(game => this.availableGames.some(g => g.code === game.gameGroupCode));
				this.isLoading = false;
				this.gamesLastDraw = games;
			}, error => {
				this.isLoading = false;
				this.getDataError = true;
			});
	}

}
