import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicContentComponent, PUBLIC_ROUTES } from './layout/public-content';
import { SecureContentComponent, SECURE_ROUTES } from './layout/secure-content';
import { PrintContentComponent } from './buy-ticket/purchase-print';
import { SecureContentGuard } from './shared/guards';
import { NoContentComponent } from './no-content';
import { PrintDrawStatsComponent } from './draw-stats/print-draw-stats/print-draw-stats.component';
import { PrintSalesStatsComponent } from './sales-stats/print-sales-stats/print-sales-stats.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full'
	},
	{
		path: '',
		component: PublicContentComponent,
		children: PUBLIC_ROUTES
	},
	{
		path: '',
		component: SecureContentComponent,
		children: SECURE_ROUTES
	},
	{
		path: 'print/:printData',
		component: PrintContentComponent,
		canActivate: [SecureContentGuard]
	},
	{
		path: 'print-sales-stats/:printData',
		component: PrintSalesStatsComponent,
		canActivate: [SecureContentGuard]
	},
	{
		path: 'print-draw-stats/:printData',
		component: PrintDrawStatsComponent,
		canActivate: [SecureContentGuard]
	},
	{
		path: '**',
		component: NoContentComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
