<div class="alert">
	<h2>{{ title }}</h2>
	<p class="content" [innerHTML]="contentText | translate | hgSafeHtml"></p>
	<p *ngIf="type === 'error' && errorCode !== undefined && errorCode !== ''">
		{{ 'general.alerts.error-code' | translate: { 'errorCode': errorCode } }}
		<br>
		{{ 'general.alerts.timestamp' | translate: { 'time': timestamp | date:'short':'+0000':localeID } }}
	</p>
	<div>
		<button *ngIf="hasConfirmBtn" class="default-btn" (click)="confirmAlert()">
			{{ confirmBtnText | translate }}
		</button>
		<button class="default-btn" (click)="closeAlert()">
			{{ closeBtnText | translate }}
		</button>
	</div>
</div>