import { Component, AfterViewInit } from '@angular/core';
import { AppOverlaysService, AuthService } from '../../shared/services';
import { ChangePasswordComponent } from '../../change-password';

@Component({
	selector: 'ra-secure-content',
	templateUrl: './secure-content.component.html',
	styleUrls: ['./secure-content.component.scss']
})
export class SecureContentComponent implements AfterViewInit {

	constructor(
		private appOverlaysService: AppOverlaysService,
		private authService: AuthService
	) { }

	ngAfterViewInit() {
		if (this.authService.authTokenData.payload.RequirePasswordChange) {
			setTimeout(() => {
				this.appOverlaysService.loadOverlay(ChangePasswordComponent);
			}, 500);
		}
	}

}
