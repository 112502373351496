import { Injectable } from '@angular/core';

import { ServiceError, GameError, _TRANSLATE } from 'helio-games-core';

import { AppOverlaysService } from './app-overlays.service';
import { AlertComponent } from '../../alert';

@Injectable()
export class ErrorHandlerService {
	constructor(
		private appOverlaysService: AppOverlaysService
	) { }

	handleError(error: ServiceError[] | Error | GameError, errorMsg?: string): void {
		if (error === undefined) {
			this.loadOverlay((alertComponent) => {
				alertComponent.type = 'error';
				alertComponent.contentText = errorMsg;
			});
		}

		if (error instanceof Error) {
			if (error instanceof GameError) {
				this.loadOverlay((alertComponent) => {
					alertComponent.type = 'error';
					alertComponent.contentText = error.translationKey;
				});
			} else {
				this.loadOverlay((alertComponent) => {
					alertComponent.type = 'error';
					alertComponent.contentText = error.message;
				});
			}
		} else if (error[0] instanceof Object) {
			if (error[0].description === 'UITimeoutError') {
				this.loadOverlay((alertComponent) => {
					alertComponent.type = 'error';
					alertComponent.contentText = _TRANSLATE('general.errors.connectivity-issues');
					alertComponent.errorCode = error[0].errorNo;
				});
			} else if (error[0].errorNo === '404') {
				this.loadOverlay((alertComponent) => {
					alertComponent.type = 'error';
					alertComponent.contentText = (errorMsg === undefined) ? _TRANSLATE('general.errors.item-not-found') : errorMsg;
					alertComponent.errorCode = error[0].errorNo;
				});
			} else {
				const alertErrorMsg = (errorMsg === undefined) ? error[0].description : errorMsg;
				this.loadOverlay((alertComponent) => {
					alertComponent.type = 'error';
					alertComponent.contentText = alertErrorMsg;
					alertComponent.errorCode = error[0].errorNo;
				});
			}
		}
	}

	private loadOverlay(setComponentInstace: (alertComponent: AlertComponent) => void) {
		this.appOverlaysService.loadOverlay(AlertComponent, setComponentInstace);
	}
}
