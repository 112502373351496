import { Component, OnInit } from '@angular/core';
import { CapabilitiesService } from '../shared/services/capabilities.service';

@Component({
	selector: 'ra-draw-stats',
	templateUrl: './draw-stats.component.html',
	styleUrls: ['./draw-stats.component.scss']
})
export class DrawStatsComponent implements OnInit {

	canPrint: boolean = true;

	constructor(private capabilities: CapabilitiesService) { }

	ngOnInit() {
		this.canPrint = this.capabilities.getCapability('printDrawStats');
	}

	print() {

	}

}
