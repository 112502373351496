import { Injectable, ComponentFactory, ComponentFactoryResolver } from '@angular/core';
import { ComponentHostDirective } from '../directives';

@Injectable()
export class AppOverlaysService {
	overlayHost: ComponentHostDirective; // Set in AppComponent

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver
	) { }

	loadOverlay(componentType: any, setComponentInstance?: (componentInstance: any) => void) {
		if (this.overlayHost) {
			const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

			const componentRef = this.overlayHost.viewContainerRef.createComponent(componentFactory);
			const componentInstance = componentRef.instance;

			if (setComponentInstance !== undefined) {
				setComponentInstance(componentInstance);
			}
		}
	}

	removeOverlay() {
		this.overlayHost.viewContainerRef.remove();
	}

	clearOverlays() {
		this.overlayHost.viewContainerRef.clear();
	}
}
