import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class WindowEventsService {
	onBlur: Observable<Event>;
	onFocus: Observable<Event>;
	onResize: Observable<Event>;

	constructor() {
		this.onBlurHandler();
		this.onFocusHandler();
		this.onResizeHandler();
	}

	private onBlurHandler() {
		this.onBlur = fromEvent(window, 'blur');
	}

	private onFocusHandler() {
		this.onFocus = fromEvent(window, 'focus');
	}

	private onResizeHandler() {
		this.onResize = fromEvent(window, 'resize')
			.pipe(
				delay(300)
			);
	}
}
