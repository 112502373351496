import { Component, OnInit, Input } from '@angular/core';
import { AgentPastDraws } from 'helio-games-core';

import { AppLangService } from '../../app-header';

@Component({
	selector: 'ra-draw-stats-card',
	templateUrl: './draw-stats-card.component.html',
	styleUrls: ['./draw-stats-card.component.scss']
})
export class DrawStatsCardComponent implements OnInit {

	@Input() lastDrawData: AgentPastDraws;

	drawDate = new Date();
	localeID: string;

	constructor(
		private appLangService: AppLangService
	) { }

	ngOnInit() {
		this.localeID = this.appLangService.selectedLocaleID;
		this.drawDate = new Date(this.lastDrawData.lastDraw.drawDate);
	}

}
