import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'numberToChar'
})
export class NumberToCharPipe implements PipeTransform {

	transform(number: number): string {
		return String.fromCharCode(number);
	}

}
