<div class="content-wrapper">
	<div class="cards-wrapper">
		<ng-container *ngIf="gamesLastDraw.length > 0 && !getDataError && !isLoading">
			<ra-draw-stats-card *ngFor="let gameLastDraw of gamesLastDraw" [lastDrawData]="gameLastDraw"></ra-draw-stats-card>
		</ng-container>

		<ng-container *ngIf="isLoading">
			<div class="loading">
				<div class="loader"></div>
			</div>
		</ng-container>
	</div>

	<ng-container *ngIf="getDataError">
		<h4>{{ 'draw-stats.get-data-error' | translate }}</h4>
	</ng-container>

	<ng-container *ngIf="gamesLastDraw.length === 0 && !getDataError && !isLoading">
		<h4>{{ 'draw-stats.no-data' | translate }}</h4>
	</ng-container>
</div>
