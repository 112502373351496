import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AppConfigService} from 'helio-games-core';
import {CreateDepositResponse} from '../models/create-deposit-response.model';
import {AuthService} from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class DepositService {

	constructor(private http: HttpClient,
				private appConfigService: AppConfigService,
				private authService: AuthService) {
	}

	createDeposit(gatewayName: string, currencyCode: string, idempotencyKey: string): Promise<CreateDepositResponse> {
		//
		const url = `${this.appConfigService.agentServiceBaseURL}api/deposits`;
		const authHeaderValue = `${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`;
		return this.http.post<CreateDepositResponse>(url, {
			gatewayName, currencyCode, idempotencyKey
		}, {
			headers: {'Authorization': authHeaderValue}
		}).toPromise();
	}

	getIdempotencyKey(): Promise<string> {
		const url = `${this.appConfigService.agentServiceBaseURL}api/deposits/idempotency`;
		return this.http.get<string>(url).toPromise();
	}

}
