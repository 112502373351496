export const environment = {
	production: true,
	appInsightsKey: 'cbb0c549-5aee-4425-92be-a919224abc6f',
	env: 'prod',
	defaultTenant: '2',
	authClientID: 'PROD-HE-RA',
	authClientSecret: 'C617C315-2FBF-11EF-B3CD-000D3A2C9626',
	inactiveTimeLimit: 3600,
	helioGameJavaScriptUrl: 'https://cdn.helioengine-ts2.com/launch-game.js'
};
