import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class AgentBalanceService {
	onAgentBalanceChange: Observable<number>;

	private agentBalance = 0;
	private agentBalanceSubject = new Subject<number>();

	constructor() {
		this.onAgentBalanceChange = this.agentBalanceSubject.asObservable();
	}

	get balance() {
		return this.agentBalance;
	}

	set balance(newAgentBalance: number) {
		this.agentBalance = newAgentBalance;

		localStorage.setItem('agentAppBalance', newAgentBalance.toString());
		this.agentBalanceSubject.next(newAgentBalance);
	}
}
