import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AppConfigService} from 'helio-games-core';
import {AppLangService} from 'src/app/app-header';
import {SalesPrintData, SalesPrintDataItem} from 'src/app/shared/models/sales-print-data.model';
import {AuthService} from 'src/app/shared/services';
import {pageFontSize} from '../../shared/utils';

@Component({
	selector: 'ra-print-sales-stats',
	templateUrl: './print-sales-stats.component.html',
	styleUrls: ['./print-sales-stats.component.scss']
})
export class PrintSalesStatsComponent implements OnInit {

	printData: SalesPrintData;
	agentName: string;

	@HostBinding('style.padding') padding = '0';

	agentId: string;
	currency: string;

	totalSold = 0;
	totalPaid = 0;
	totalCancelled = 0;
	endingBalance = 0;

	localeID: string;

	constructor(private route: ActivatedRoute,
				private authService: AuthService,
				private appLangService: AppLangService,
				private appConfigService: AppConfigService,
				private translate: TranslateService) {
	}

	ngOnInit() {

		this.agentName = this.authService.authTokenData.payload.FullName;
		this.agentId = this.authService.authTokenData.payload.EntityID;
		this.currency = this.authService.authTokenData.payload.CurrencyCode;
		this.localeID = this.appLangService.selectedLocaleID;

		if (this.printData === undefined) {
			this.route.paramMap.subscribe((params) => {
				const printData = params.get('printData');

				if (printData !== null) {
					const jsonString = atob(printData);
					this.printData = JSON.parse(jsonString);

					this.translate.get('sales-stats.all-games')
						.toPromise().then(val => {
						this.printData.items = this.printData.items.filter(i => i.game !== val);

						this.totalSold = this.printData.items.reduce<number>((total: number, t: SalesPrintDataItem) => {
							return total + t.sold;
						}, 0);

						this.totalPaid = this.printData.items.reduce<number>((total: number, t: SalesPrintDataItem) => {
							return total + t.paid;
						}, 0);

						this.totalCancelled = this.printData.items.reduce<number>((total: number, t: SalesPrintDataItem) => {
							return total + t.cancelled;
						}, 0);

						this.endingBalance = this.totalSold - this.totalPaid;

						this.setStyle();
					});


				}
			});
		} else {
			// this.totalCost = this.purchasedTickets.reduce((total: number, t: PurchasedTicket) => {
			// 	return total + t.ticketCost;
			// }, 0);
		}
	}

	getGameInitials(game: string) {
		const reduction = game.split(' ')
			.map(word => word[0])
			.join('');

		return reduction;
	}

	private setStyle() {
		this.padding = '15px';

		const html = document.getElementsByTagName('html')[0];
		const body = document.getElementsByTagName('body')[0];

		const pageSize = (localStorage.getItem('agentAppPageSize') === null) ? 80 : parseInt(localStorage.getItem('agentAppPageSize'), 10);

		const fontSizeConfig = pageFontSize.item(pageSize);

		html.style.width = fontSizeConfig.pageWidthMM;
		html.style.fontSize = fontSizeConfig.fontSizePercentage;

		html.style.height = 'auto';
		body.style.height = 'auto';
	}

}
