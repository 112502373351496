import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

import { AuthService } from '../../services';

@Injectable()
export class LoginGuard implements CanActivate {

	constructor(
		private authService: AuthService,
		private router: Router
	) { }

	canActivate(): Observable<boolean> {
		if (this.authService.isLoggedIn) {
			this.router.navigate(['home']);
			return of(false);
		}

		return of(true);
	}

}
