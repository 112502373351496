<div class="login-wrapper">
	<div class="form-wrapper">
		<header>
			<div class="helio-logo"></div>
		</header>

		<h3>{{ 'login.title' | translate }}</h3>

		<form [formGroup]="loginForm" (ngSubmit)="login()">
			<div>
				<label for="username">{{ 'login.username-lbl' | translate }}</label>
				<div>
					<input id="username" formControlName="username" type="text">
				</div>
			</div>
			<div>
				<label for="password">{{ 'login.password-lbl' | translate }}</label>
				<div>
					<p-password id="password" formControlName="password" [toggleMask]="true"
								[feedback]="false"></p-password>
				</div>
			</div>
			<div>
				<button [disabled]="!loginForm.valid" class="default-btn" [class.is-loading]="isLoginLoading" type="submit">
					<span>{{ 'login.login-btn' | translate }}</span>
				</button>
			</div>
		</form>
		<!-- <ra-language-select></ra-language-select> -->
	</div>
	<p *ngIf="loginFailed">
		<span>{{ 'login.login-error' | translate }}</span>
	</p>
</div>
