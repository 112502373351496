import {Component, EventEmitter, Output} from '@angular/core';
import {throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {
	AgentPlaceBetService,
	AppConfigService,
	BuyButtonEmitData,
	BuyTicketData, Coupon,
	FreeTicketsService,
	GameConfigService,
	GameError,
	GameErrors,
	LoginService, MathUtilities, Participation, ParticipationFreeTicket,
	PlaceBetRequest,
	PlaceBetService,
	PlayerBalanceService, PlayerSubscription,
	PostMessagesService, ServiceError, SubscriptionType, Ticket, TicketEntry, TicketEntryBoard,
	UIFreeTicket
} from 'helio-games-core';
import {RetailAgentService} from '../../shared/services/retail-agent.service';
import {LogService} from '../../shared/services/log.service';

/**
 * Contains the logic related to placing a bet
 */
@Component({
	selector: 'ra-buy-button',
	standalone: true,
	template: `
		<ng-content></ng-content>
	`
})
export class BuyButtonComponent {
	/** Triggered whenever the buy function is called */
	@Output() bet: EventEmitter<BuyButtonEmitData> = new EventEmitter<BuyButtonEmitData>();

	private requestPending = false;
	private enoughBalance = false;
	private usedFreeTickets: UIFreeTicket[];

	constructor(
		private appConfigService: AppConfigService,
		private placeBetService: PlaceBetService,
		private agentPlaceBetService: AgentPlaceBetService,
		private retailAgentService: RetailAgentService,
		private gameConfigService: GameConfigService,
		private playerBalanceService: PlayerBalanceService,
		private freeTicketsService: FreeTicketsService,
		private postMessagesService: PostMessagesService,
		private loginService: LoginService
	) {
	}

	/**
	 * When the function is called, a `Coupon` object is created and sent to the server to place a bet.
	 * Whenever this function is called the `bet` Event Emitter is triggered.
	 * @param buyTicketData Data representing the numbers that we selected by the player
	 * @param isLoggedIn whether the user is login
	 * @param enoughBalance if the associated account has enough funds to cover the request
	 */
	buy(buyTicketData: BuyTicketData[], isLoggedIn?: boolean, enoughBalance?: boolean) {
		if (!this.requestPending) {
			this.requestPending = true;
			const coupon = this.createCombineCouponObject(buyTicketData);

			// IMPORTANT: Assuming that subscription can only be used with a coupon that has a single ticket (i.e. betting on a single game)
			let subscription = null;

			if (buyTicketData[0].hasSubscription) {
				if (buyTicketData.length === 1) {
					subscription = this.createSubscriptionObject(buyTicketData[0]);
				} else {
					console.warn('Helio Gaming Core - Cannot set a subscription with for a coupon with multiple tickets');
				}
			}

			const postData = new PlaceBetRequest(
				this.gameConfigService.apiKey,
				this.gameConfigService.sessionKey,
				this.gameConfigService.gameGroupCode,
				coupon,
				subscription
			);

			let observable;

			if (this.appConfigService.demoEnabled) {
				observable = this.placeBetService.getMock();
				if (localStorage.getItem('lottoHeroDemoBets') === null) {
					const demoBets = {
						bets: []
					};
					localStorage.setItem('lottoHeroDemoBets', JSON.stringify(demoBets));
				}

				const lhBets = JSON.parse(localStorage.getItem('lottoHeroDemoBets'));
				lhBets.bets.push(coupon);
				localStorage.setItem('lottoHeroDemoBets', JSON.stringify(lhBets));
			} else {
				// The current setup is such that either arg or dep values can be taken as truth, depending on which is present,
				// to support backwards compat on any other app that may be currently utilising this method
				if (!isLoggedIn && !this.loginService.isLoggedIn) {
					// TEXT TO TRANSLATE
					observable = throwError(new GameError(GameErrors.LoginToPlaceBet));
					this.postMessagesService.postMessagePlayerNotLoggedIn();
					LogService.devLog('buyButton: notLoggedIn')
				} else if (enoughBalance || this.enoughBalance) {
					// observable = this.placeBetService.post(`${ServiceAction.PLACE_BET}/${postData.vertical}`, postData);
					observable = this.retailAgentService.purchaseAndPrint(postData);
					LogService.devLog('buyButton: placeBetService')
				} else {
					// TEXT TO TRANSLATE
					observable = throwError(new GameError(!this.gameConfigService.freeTicketsOnly ?
						GameErrors.InsufficientBalanceToPlaceBet : GameErrors.InsufficientFreeTicketsToPlaceBet));
					this.postMessagesService.postMessageInsufficientFunds();
					LogService.devLog('buyButton: insufficient')
				}
			}

			observable.pipe(
				map((res: any) => {
					if (!res.isSuccess) {
						throw new GameError(GameErrors.BetCouldNotBeProcessed);
					}

					return res;
				})
			).subscribe({
				next: res => {
					LogService.devLog('buyButton: observable next')
					if (res.balance && !this.appConfigService.useFreeTicketsAsBalance) {
						const balance = res.balance.balance;
						this.playerBalanceService.setPlayerBalance(balance);
					}

					if (this.appConfigService.useFreeTicketsAsBalance) {
						this.freeTicketsService.getFreeTicketsBalance()
							.subscribe(() => {
								this.playerBalanceService.setPlayerBalance(this.freeTicketsService.totalFreeTickets);
							});
					}

					// if using freeTickets array of BuyTicketData object
					if (this.usedFreeTickets.length > 0) {
						this.freeTicketsService.totalFreeTickets -= this.usedFreeTickets.length;
						this.freeTicketsService.getFreeTickets().subscribe();
					}

					// if using multiDraws, get total of free Bets to be used
					const numFreeBets = buyTicketData.map(tempData => {
						if (tempData.isMultiDraws) {
							return tempData.numFreeBets;
						}
					}).reduce((a, b) => a + b);

					if (numFreeBets > 0) {
						this.freeTicketsService.totalFreeTickets -= numFreeBets;
						this.freeTicketsService.getFreeTickets().subscribe();
					}

					this.requestPending = false;
					this.bet.emit(new BuyButtonEmitData(true, res, this.usedFreeTickets));
					this.usedFreeTickets = [];
				},
				error: (error: ServiceError[] | GameError) => {
					LogService.devError('buyButton: observable error:', error)

					if (!this.enoughBalance) {
						this.bet.emit(new BuyButtonEmitData(false, error, undefined, error));
					} else {
						// TEXT TO TRANSLATE
						const gameError = new GameError(GameErrors.BetCouldNotBeProcessed).translationKey;
						this.bet.emit(new BuyButtonEmitData(false, error, undefined, error, gameError));
					}

					this.usedFreeTickets = [];
					this.requestPending = false;
				}
			});
		}
	}

	/**
	 * Function used to create the Coupon object expected by the server
	 *
	 * @summary Similar to {@link createCombineCouponObject} with the exception that all entries within buyTicketData,
	 * regardless of gameID, are parsed as distinct {@link Ticket}.
	 * @param buyTicketData Array of BuyTicketData used to represent the tickets to be bought by the player
	 * @return Coupon object expected by the server
	 */
	private createCouponObject(buyTicketData: BuyTicketData[]): Coupon {
		const tickets: Ticket[] = [];
		const participationCosts: number[] = [];
		this.usedFreeTickets = [];
		let couponCost = 0;

		buyTicketData.forEach(ticketData => {
			// assuming all selected draws have the same financial rule id
			// const tempTicketFinancialRuleID = ticketData.gameFinancialRuleID;
			const ticketEntries: TicketEntry[] = [];
			let ticketCost = 0;

			const ticketPrice = ticketData.linePrice;

			// create ticket entries
			ticketData.ticketSelectedItems.forEach((ticketSelectedItems) => {
				const ticketEntryBoards: TicketEntryBoard[] = [];
				let numBets = 1;
				let cost: number;

				// create ticket entry boards
				ticketSelectedItems.selectedItems.forEach(boardSelectedItems => {
					const boardSelectionType = (ticketData.boardSelectionType === undefined) ?
						boardSelectedItems.boardSelectionType : ticketData.boardSelectionType;

					ticketEntryBoards.push(new TicketEntryBoard(
						boardSelectedItems.boardData.boardNum,
						boardSelectedItems.pickedNumbers,
						boardSelectionType
					));

					const boardCombinations =
						MathUtilities.combinations(boardSelectedItems.pickedNumbers.length, boardSelectedItems.boardData.numColumnsCoupon);

					numBets *= boardCombinations;
				});

				cost = numBets * ticketPrice;

				ticketEntries.push(new TicketEntry(numBets, ticketEntryBoards));
				participationCosts.push(cost);
			});

			let multiDrawsFreeTickets = ticketData.numFreeBets;
			ticketEntries.forEach((ticketEntry, ticketEntryIndex) => {
				if (!ticketData.isMultiDraws) {
					// create participations
					let participationCost = participationCosts[ticketEntryIndex];

					ticketEntry.participations = ticketData.selectedDraws.draws.map((draw) => {
						if (ticketData.freeTickets.length > 0) {
							const length: number = (ticketEntry.numBets < ticketData.freeTickets.length) ? ticketEntry.numBets : ticketData.freeTickets.length;
							const freeTickets: UIFreeTicket[] = ticketData.freeTickets
								.sort((a, b) => {
									return new Date(a.expiry).getTime() - new Date(b.expiry).getTime();
								}).splice(0, length);

							const participationFreeTickets: ParticipationFreeTicket[] = [];
							let totFreeTicketsAmount = 0;

							for (let i = 0; i < freeTickets.length; i++) {
								totFreeTicketsAmount++;
								const freeTicket: UIFreeTicket = freeTickets[i];

								if (i === 0) {
									participationFreeTickets.push(new ParticipationFreeTicket(freeTicket.freeTicketBatchID, 1));
								} else {
									const obj = participationFreeTickets[participationFreeTickets.length - 1];

									if (freeTicket.freeTicketBatchID !== obj.freeTicketBatchID) {
										participationFreeTickets.push(new ParticipationFreeTicket(freeTicket.freeTicketBatchID, 1));
									} else {
										obj.amount += 1;
									}
								}
								this.usedFreeTickets.push(freeTicket);
							}

							participationCost -= (totFreeTicketsAmount * ticketPrice);
							ticketCost += participationCost;

							return new Participation(draw.drawID, participationCost, participationFreeTickets);
						} else {
							ticketCost += participationCost;
							return new Participation(draw.drawID, participationCost);
						}
					});
				} else {
					ticketEntry.advancedDraws = ticketData.multiDrawsData.advancedDraws;
					ticketEntry.multiDraws = (ticketData.subscriptionType === SubscriptionType.JackpotHunt) ? 1 : ticketData.multiDrawsData.multiDraws;
					ticketEntry.schedulerNumbers = ticketData.multiDrawsData.schedulerNumbers;

					let freeTicketsCost = 0;
					if (multiDrawsFreeTickets > 0) {
						const ticketEntryFreeTicketsAmount = (multiDrawsFreeTickets > (ticketEntry.multiDraws * ticketEntry.numBets)) ?
							multiDrawsFreeTickets - (ticketEntry.multiDraws * ticketEntry.numBets) : multiDrawsFreeTickets;

						freeTicketsCost = ticketPrice * ticketEntryFreeTicketsAmount;
						multiDrawsFreeTickets -= ticketEntryFreeTicketsAmount;
					}

					ticketCost += (ticketPrice * ticketEntry.numBets * ticketEntry.multiDraws) - freeTicketsCost;
				}
			});

			couponCost += ticketCost;

			tickets.push(
				new Ticket(
					ticketData.gameID,
					+ticketCost.toFixed(2),
					ticketEntries,
					ticketData.isPrimaryTicket,
					ticketData.numFreeBets
				)
			);
		});

		this.hasEnoughBalance(couponCost);

		if (buyTicketData[0].preDefinedCouponID) {
			return new Coupon(tickets, buyTicketData[0].preDefinedCouponID);
		}

		return new Coupon(tickets);
	}

	/**
	 * Similar to {@link createCouponObject} with the exception that all entries with the same gameID are
	 * amalgamated under one {@link Ticket}'s {@link Ticket#ticketEntries} values, and the total price of all
	 * amalgamated entries also reflected in {@link Ticket#ticketCost}
	 */
	private createCombineCouponObject(buyTicketData: BuyTicketData[]): Coupon {
		const coupon: Coupon = this.createCouponObject(buyTicketData);
		const tickets = coupon.tickets;

		const result = [];

		tickets.forEach(item => {
			const existingItem = result.find(r => r.gameID === item.gameID);

			if (existingItem) {
				existingItem.ticketCost += item.ticketCost;

				existingItem.ticketEntries = existingItem.ticketEntries.concat(item.ticketEntries);
			} else {
				// Create a and init a new object
				const temp = {...item};
				temp.ticketCost = item.ticketCost;
				temp.ticketEntries = [...item.ticketEntries];
				result.push(temp);
			}
		});

		coupon.tickets = result;
		return coupon;
	}

	private createSubscriptionObject(buyTicketData: BuyTicketData): PlayerSubscription {
		return {
			duration: (buyTicketData.subscriptionType === SubscriptionType.JackpotHunt) ? null : buyTicketData.multiDrawsData.multiDraws,
			schedulerNumbers: buyTicketData.multiDrawsData.schedulerNumbers,
			advancedDraws: buyTicketData.multiDrawsData.advancedDraws,
			jackpotMinimum: null,
			subscriptionType: buyTicketData.subscriptionType
		};
	}

	private hasEnoughBalance(cost: number) {
		if (!this.gameConfigService.freeTicketsOnly) {
			this.enoughBalance = (this.playerBalanceService.playerBalance >= cost);
		} else {
			this.enoughBalance = (this.freeTicketsService.totalFreeTickets >= cost);
		}
	}
}

