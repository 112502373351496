import { Component, OnInit, HostBinding, Input, Output, EventEmitter } from '@angular/core';

import { _TRANSLATE } from 'helio-games-core';

import { appOverlaysAnimations } from '../app-overlays.animations';
import { AppOverlaysService } from '../shared/services';
import { AppLangService } from '../app-header';

@Component({
	selector: 'ra-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	animations: appOverlaysAnimations
})
export class AlertComponent implements OnInit {

	@HostBinding('@fade') fade = true;

	title = 'Info';

	@Input() type: 'error' | 'info' | 'warn' = 'info';
	@Input() contentText = '';

	@Input() hasConfirmBtn = false;
	@Input() confirmBtnText = _TRANSLATE('general.alerts.confirm-btn');
	@Output() confirmBtnClick = new EventEmitter<void>();

	@Input() closeBtnText = _TRANSLATE('general.alerts.ok-btn');
	@Output() closeBtnClick = new EventEmitter<void>();

	@Input() errorCode: string;

	timestamp: Date;

	localeID: string;

	constructor(
		private appOverlaysService: AppOverlaysService,
		private appLangService: AppLangService
	) { }

	ngOnInit() {
		this.localeID = this.appLangService.selectedLocaleID;
		this.setAlertTitle();

		if (this.type === 'error') {
			this.setErrorTimestamp();
		}
	}

	confirmAlert() {
		this.confirmBtnClick.next();
	}

	closeAlert() {
		this.appOverlaysService.removeOverlay();
		this.closeBtnClick.next();
	}

	private setAlertTitle() {
		switch (this.type) {
			case 'error':
				this.title = 'Error';
				break;
			case 'warn':
				this.title = 'Warn';
				break;
			case 'info':
			default:
				this.title = 'Info';
				break;
		}
	}

	private setErrorTimestamp() {
		if (this.errorCode !== undefined && this.errorCode !== '') {
			this.timestamp = new Date();
		}
	}

}
