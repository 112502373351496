import { Component, OnInit, Input } from '@angular/core';
import { AgentStat } from 'helio-games-core';
import { AuthService } from '../../shared/services';
import { AppLangService } from '../../app-header';

@Component({
	selector: 'ra-stats-card',
	templateUrl: './stats-card.component.html',
	styleUrls: ['./stats-card.component.scss']
})
export class StatsCardComponent implements OnInit {

	@Input() agentStat: AgentStat;

	localeID: string;
	currencyCode: string;

	constructor(
		private authService: AuthService,
		private appLangService: AppLangService
	) { }

	ngOnInit() {
		this.localeID = this.appLangService.selectedLocaleID;
		this.currencyCode = this.authService.authTokenData.payload.CurrencyCode;
	}

}
