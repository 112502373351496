import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConfigService } from 'helio-games-core';
import { Subscription } from 'rxjs';
import { AppLangService } from 'src/app/app-header';
import { BuyTicketService } from 'src/app/buy-ticket';
import { AuthService } from 'src/app/shared/services';
import { pageFontSize } from '../../shared/utils';

@Component({
  selector: 'ra-print-draw-stats',
  templateUrl: './print-draw-stats.component.html',
  styleUrls: ['./print-draw-stats.component.scss']
})
export class PrintDrawStatsComponent implements OnInit {
  @Input() draws?: any;

	@HostBinding('style.padding') padding = '0';

	isPrintPage = false;	

	localeID: string;

  gameName: string;

	constructor(
		private route: ActivatedRoute,
		private appLangService: AppLangService,
	) { }

	ngOnInit() {		
		this.localeID = this.appLangService.selectedLocaleID;				
	

		if (this.draws === undefined) {
			this.route.paramMap.subscribe((params) => {
				const printData = params.get('printData');

        console.log('print data: ' + JSON.stringify(printData));

				if (printData !== null) {
					this.isPrintPage = true;
					const jsonString = atob(printData);
					let payload: any = JSON.parse(jsonString);					

          this.draws = payload.draws;
          this.gameName = payload.gameName;

          console.log('payload: ' + JSON.stringify(payload));

					this.setStyle();
				}
			});
		}
	}

	private setStyle() {
		this.padding = '15px';

		const html = document.getElementsByTagName('html')[0];
		const body = document.getElementsByTagName('body')[0];

		const pageSize = (localStorage.getItem('agentAppPageSize') === null) ? 80 : parseInt(localStorage.getItem('agentAppPageSize'), 10);

		const fontSizeConfig = pageFontSize.item(pageSize);

		html.style.width = fontSizeConfig.pageWidthMM;
		html.style.fontSize = fontSizeConfig.fontSizePercentage;

		html.style.height = 'auto';
		body.style.height = 'auto';
	}
}