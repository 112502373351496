import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
	HelioGameServicesModule, AppConfigService, HelioCouponPickerModule,
	HelioBoardModule, HelioBuyButtonModule, HelioDropdownModule, HelioGamePipesModule
} from 'helio-games-core';
import { TranslateModule, TranslateLoader, TranslateCompiler, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AlertComponent } from './alert';
import { AgentLobbyComponent } from './agent-lobby';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { appInitLoader } from './app-init.loaders';
import { AppHeaderComponent, AppLangSelectComponent, AppLangService } from './app-header';
import { BuyTicketComponent, BuyTicketResolver, BuyTicketService } from './buy-ticket';
import { CouponPickerComponent } from './buy-ticket/coupon-picker';
import { PurchasePrintComponent, PrintContentComponent, PrintTicketComponent, NumberToCharPipe } from './buy-ticket/purchase-print';
import { CheckTicketComponent, ScannedTicketComponent, TicketParticipationComponent, ScanTicketComponent } from './check-ticket';
import { PublicContentComponent } from './layout/public-content';
import { SecureContentComponent } from './layout/secure-content';
import { NavComponent } from './nav';
import { LoginComponent } from './login';
import { ComponentHostDirective } from './shared/directives';
import {
	AppLayoutService, AuthService, WindowEventsService, ErrorHandlerService,
	AppOverlaysService, AgentBalanceService, PwaService, GamesService
} from './shared/services';
import { LoginGuard, SecureContentGuard } from './shared/guards';
import { SalesStatsComponent, StatsListComponent, StatsCardComponent } from './sales-stats';
import { PrinterSetupComponent } from './printer-setup';
import { CancelTicketService } from './check-ticket/scanned-ticket';
import { ChangePasswordComponent, ChangePasswordService } from './change-password';
import { AppErrorsModule } from './error';
import { AppLoaderComponent } from './app-loader';
import { CommentModalComponent } from './comment-modal';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { InstallAppComponent } from './install-app/install-app.component';
import { InstallAppGuard } from './install-app';
import { NoContentComponent } from './no-content';
import {
	DrawStatsComponent, DrawStatsCardComponent, DrawStatsListComponent,
	GameDrawStatsComponent, FormatDrawResultPipe
} from './draw-stats';
import { PaymentSuccessComponent } from './payments/payment-success/payment-success.component';
import { PaymentFailedComponent } from './payments/payment-failed/payment-failed.component';
import { PrintDrawStatsComponent } from './draw-stats/print-draw-stats/print-draw-stats.component';
import { CurrencyPipe } from '@angular/common';
import { CurrencyWithSpacePipe } from './shared/utils/currency-filter.util';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { PrintSalesStatsComponent } from './sales-stats/print-sales-stats/print-sales-stats.component';
import {QrCodeModule} from 'ng-qrcode';
import {PasswordModule} from 'primeng/password';
import {BuyButtonComponent} from './buy-ticket/buy-button/buy-button.component';

registerLocaleData(localeFr);

@NgModule({
	declarations: [
		AppComponent,
		AgentLobbyComponent,
		BuyTicketComponent,
		CouponPickerComponent,
		PurchasePrintComponent,
		PrintTicketComponent,
		ComponentHostDirective,
		CheckTicketComponent,
		ScannedTicketComponent,
		TicketParticipationComponent,
		NumberToCharPipe,
		LoginComponent,
		PublicContentComponent,
		SecureContentComponent,
		AppHeaderComponent,
		AlertComponent,
		ScanTicketComponent,
		NavComponent,
		PrintContentComponent,
		SalesStatsComponent,
		PrinterSetupComponent,
		AppLangSelectComponent,
		StatsListComponent,
		StatsCardComponent,
		ChangePasswordComponent,
		AppLoaderComponent,
		CommentModalComponent,
		InstallAppComponent,
		NoContentComponent,
		DrawStatsComponent,
		GameDrawStatsComponent,
		DrawStatsCardComponent,
		DrawStatsListComponent,
		FormatDrawResultPipe,
		CurrencyWithSpacePipe,
		PaymentSuccessComponent,
		PaymentFailedComponent,
		PrintDrawStatsComponent,
		PrintSalesStatsComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		AppRoutingModule,
		AppErrorsModule,
		NgxDatatableModule,

		HelioGameServicesModule,
		HelioCouponPickerModule,
		HelioBoardModule,
		HelioBuyButtonModule,
		HelioDropdownModule,
		HelioGamePipesModule,
		ZXingScannerModule,
		QrCodeModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			},

			compiler: {
				provide: TranslateCompiler,
				useClass: TranslateMessageFormatCompiler
			}
		}),
		PasswordModule,
		BuyButtonComponent,
		ServiceWorkerModule.register('ngsw-worker.js', {
			// enabled: environment.env !== 'dev-server',
			enabled: true,
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: 'registerWhenStable:30000'
		})
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: appInitLoader,
			deps: [ AppConfigService, AppLangService, TranslateService ], // , PwaService
			multi: true
		},
		AuthService,
		AppOverlaysService,
		AppLayoutService,
		BuyTicketResolver,
		BuyTicketService,
		ErrorHandlerService,
		WindowEventsService,
		AppLangService,
		CancelTicketService,
		ChangePasswordService,
		AgentBalanceService,
		PwaService,
		GamesService,
		CurrencyPipe,
		LoginGuard,
		SecureContentGuard,
		InstallAppGuard
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
	// For some reason, the contents for translation are split between two folders: './assets/i18n/' and
	// './assets/global-i18n/', whilst this worked for Angular8, contents of './assets/global-i18n/' are not recognised
	//  as its child files are no longer implicitly applied. This need copying over to './assets/i18n/', within their
	// applicable filenames, making sure to maintain their JSON structure.
	return new TranslateHttpLoader(http, './assets/i18n/');
}
