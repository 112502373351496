import { trigger, style, transition, animate } from '@angular/animations';

export const appOverlaysAnimations: any = [
	trigger('fade', [
		transition(':enter', [
			style({ opacity: 0, }),
			animate('250ms', style({ opacity: 1 }))
		]),
		transition(':leave', [
			style({ opacity: 1 }),
			animate('250ms', style({ opacity: 0 }))
		])
	])
];
