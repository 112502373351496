import {Injectable} from '@angular/core';
import {NoNewVersionDetectedEvent, SwUpdate, VersionReadyEvent} from '@angular/service-worker';
import {TranslateService} from '@ngx-translate/core';
import {AppOverlaysService} from './app-overlays.service';
import {AlertComponent} from '../../alert';
import {filter} from 'rxjs';

@Injectable()
export class PwaService {
	promptEvent: any;

	constructor(
		private swUpdate: SwUpdate,
		private overlaysService: AppOverlaysService,
		private translateService: TranslateService
	) {
		// swUpdate.available
		this.swUpdate.versionUpdates
			.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
			.subscribe({
				next: res => {
					console.log('this.swUpdate.versionUpdatesL res = ', res)
					this.overlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
						// if (alertComponent) {}
						alertComponent.contentText = this.translateService.instant('general.app-update.message');
						alertComponent.closeBtnText = this.translateService.instant('general.app-update.update-btn');

						alertComponent.closeBtnClick.subscribe(() => {
							window.location.reload();
						});
					});
				}
			});

		this.swUpdate.versionUpdates
			.pipe(filter((evt): evt is NoNewVersionDetectedEvent => evt.type === 'NO_NEW_VERSION_DETECTED'))
			.subscribe({
				next: res => {
					console.log('this.swUpdate.versionUpdatesL NoNewVersionDetectedEvent = ', res)
				}
			});

		// swUpdate.activated
		/*this.swUpdate.activateUpdate().then(() => {
			this.overlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
				if (alertComponent) {
					alertComponent.contentText = this.translateService.instant('general.app-update.updated-success');
				}
			});
		});*/
	}
}
