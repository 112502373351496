import { Component, Input, OnInit } from '@angular/core';
import { ScannedTicketParticipation } from '../../shared/models';
import { AppLangService } from '../../../app-header';

@Component({
	selector: 'ra-ticket-participation',
	templateUrl: './ticket-participation.component.html',
	styleUrls: ['./ticket-participation.component.scss']
})
export class TicketParticipationComponent implements OnInit {
	@Input() ticketEntryNumber: number;
	@Input() participationData: ScannedTicketParticipation;

	localeID: string;
	isRaffle: boolean; // if selectedNumbers.length == 1, then this number assumed to be Raffle

	constructor(
		private appLangService: AppLangService
	) { }

	ngOnInit() {
		this.localeID = this.appLangService.selectedLocaleID;

		// todo - for some reason, this.participationData.selectedNumbers is an array of array,
		//  if there is no benefit to this, refactor from ScannedTicketParticipationNumber[][] to
		//  ScannedTicketParticipationNumber[] to avoid possible confusion and bugs
		this.isRaffle = this.participationData.selectedNumbers[0].length <= 1;
	}
}
