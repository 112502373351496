<div class="card-wrapper">
	<div class="game-logo">
		<div class="logo {{ lastDrawData.gameGroupCode }}"></div>
	</div>
	<div class="info">
		<h2>{{ lastDrawData.name }}</h2>
		<span>{{ 'draw-stats.card.last-draw' | translate:{drawDate: drawDate | date:'short':undefined:localeID } }}</span>
		<div class="draw-result">
			{{ lastDrawData.lastDraw.results | raFormatDrawResult }}
		</div>
	</div>
</div>
<div class="footer">
	<a routerLink="/draw-stats/{{ lastDrawData.gameGroupCode }}" [queryParams]="{name: lastDrawData.name}">
		<button class="default-btn">
			{{ 'draw-stats.card.view-more-btn' | translate }}
		</button>
	</a>
</div>
