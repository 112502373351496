import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { map } from 'rxjs/operators';
import { AgentSalesStatsService, AgentStat } from 'helio-games-core';

import { AuthService } from '../../shared/services';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'ra-stats-list',
	templateUrl: './stats-list.component.html',
	styleUrls: ['./stats-list.component.scss']
})
export class StatsListComponent implements OnInit, OnChanges {

	@Input() dateFrom: Date;
	@Input() dateTo: Date;

	stats: AgentStat[] = [];

	@Output() onStatsReceived = new EventEmitter<AgentStat[]>();

	isLoading = false;

	getDataError = false;

	constructor(
		private agentSalesStatsService: AgentSalesStatsService,
		private authService: AuthService,
		private translate: TranslateService
	) { }

	ngOnInit() {
		this.getStats();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes !== undefined) {
			if (!changes['dateFrom'].isFirstChange() && !changes['dateTo'].isFirstChange()) {
				if (changes['dateFrom'].currentValue !== changes['dateFrom'].previousValue ||
					changes['dateTo'].currentValue !== changes['dateTo'].previousValue) {

					this.getStats();
				}
			}
		}
	}

	private getStats = async () => {
		if (!this.isLoading) {
			this.isLoading = true;
			this.getDataError = false;

			const authToken = `${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`;
			let allGames = await this.translate.get('sales-stats.all-games').toPromise();

			try {

				let stats = await this.agentSalesStatsService.getStatistics(this.dateFrom, this.dateTo, authToken).toPromise();

				this.isLoading = false;			
	
				if (stats.retailAgentStats.length > 0) {
					let totalSales = 0;
					let totalPayouts = 0;
					let totalCancelled = 0;
	
					stats.retailAgentStats.forEach(stat => {						
						totalSales += stat.totalSales;
						totalPayouts += stat.totalPayouts;
						totalCancelled += stat.totalCancelled;
					});							
	
					stats.retailAgentStats.unshift({
						gameName: allGames,
						gameGroupCode: '',
						totalSales: totalSales,
						totalPayouts: totalPayouts,
						totalCancelled: totalCancelled
					});							
				}
	
				this.onStatsReceived.emit(stats.retailAgentStats);
				this.stats = stats.retailAgentStats;
				return stats;
			} catch(ex) {
				this.isLoading = false;
				this.getDataError = true;
			}			
		}
	}

}
