import { Routes } from '@angular/router';
import { AgentLobbyComponent } from '../../agent-lobby';
import { BuyTicketComponent, BuyTicketResolver } from '../../buy-ticket';
import { SecureContentGuard } from '../../shared/guards/secure-content';
import { SalesStatsComponent } from '../../sales-stats';
import { PrinterSetupComponent } from '../../printer-setup';
import { InstallAppComponent, InstallAppGuard } from '../../install-app';
import { DrawStatsComponent, GameDrawStatsComponent } from '../../draw-stats';
import { PaymentSuccessComponent } from 'src/app/payments/payment-success/payment-success.component';
import { PaymentFailedComponent } from 'src/app/payments/payment-failed/payment-failed.component';
import { PrintSalesStatsComponent } from 'src/app/sales-stats/print-sales-stats/print-sales-stats.component';

export const SECURE_ROUTES: Routes = [
	{
		path: 'home',
		component: AgentLobbyComponent,
		canActivate: [SecureContentGuard]
	},
	{
		path: 'buy-ticket/:gameGroupCode',
		component: BuyTicketComponent,
		resolve: { buyTicketPageData: BuyTicketResolver },
		canActivate: [SecureContentGuard]
	},
	{
		path: 'draw-stats',
		component: DrawStatsComponent,
		canActivate: [SecureContentGuard],
	},
	{
		path: 'draw-stats/:gameGroupCode',
		component: GameDrawStatsComponent,
		canActivate: [SecureContentGuard]
	},
	{
		path: 'sales-stats',
		component: SalesStatsComponent,
		canActivate: [SecureContentGuard]
	},
	{
		path: 'printer-setup',
		component: PrinterSetupComponent,
		canActivate: [SecureContentGuard]
	},
	{
		path: 'install-app',
		component: InstallAppComponent,
		canActivate: [SecureContentGuard, InstallAppGuard]
	},
	{
		path: 'payment/success',
		component: PaymentSuccessComponent,
		canActivate: [SecureContentGuard]
	},
	{
		path: 'payment/issue',
		component: PaymentFailedComponent,
		canActivate: [SecureContentGuard]
	}
];
