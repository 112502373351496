import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AgentDrawHistoryService } from 'helio-games-core';

import { AuthService } from '../../shared/services';
import { AppLangService } from '../../app-header';
import { CapabilitiesService } from 'src/app/shared/services/capabilities.service';
import { DrawDropdownItem } from 'src/app/buy-ticket/coupon-picker/draw-dropdown.data';

@Component({
	selector: 'ra-game-draw-stats',
	templateUrl: './game-draw-stats.component.html',
	styleUrls: ['./game-draw-stats.component.scss']
})
export class GameDrawStatsComponent implements OnInit, AfterViewInit {

	localeID: string;
	canPrint = true

	gameName = '';

	totalRows = 0;
	data = [];

	pageSizeBeforePrint = 10;
	pageSize = 10;
	pageNumber = 0;

	pageSizes = [{ size: 10 }, { size: 20 }, { size: 50 }, { size: 100 }];
	selectedSize = [this.pageSizes[0]];

	isTableLoading = false;

	private gameGroupCode = '';

	@ViewChild('printDrawStatsIframeDiv', { static: false }) printDrawStatsIframeDiv: ElementRef;
	private iframeElement: HTMLIFrameElement;

	constructor(
		private agentDrawHistoryService: AgentDrawHistoryService,
		private authService: AuthService,
		private appLangService: AppLangService,
		private route: ActivatedRoute,
		private capabilities: CapabilitiesService
	) { }

	ngOnInit() {
		this.localeID = this.appLangService.selectedLocaleID;

		this.route.paramMap.subscribe(params => {
			this.gameGroupCode = params.get('gameGroupCode');

			this.route.queryParams.subscribe(urlParams => {
				this.gameName = urlParams['name'];
				this.getGamePastDraws();
			});
		});

		this.canPrint = this.capabilities.getCapability('printDrawHistory');
	}

	ngAfterViewInit() {
	}

	setPage(pageInfo) {
		this.pageNumber = pageInfo.offset;
		this.getGamePastDraws();
	}

	private getGamePastDraws() {
		this.isTableLoading = true;

		const authToken = `${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`;
		this.agentDrawHistoryService.getGamePastDraws(this.gameGroupCode, this.pageNumber, this.pageSize, authToken)
			.subscribe(response => {
				this.isTableLoading = false;
				this.data = response.resultSet;
				this.totalRows = response.totalRowCount;
				this.createPrintIframe();
			});
	}

	print() {
		const iframeTarget: HTMLElement = this.printDrawStatsIframeDiv.nativeElement;

		if (iframeTarget.hasChildNodes()) {
			this.iframeElement.contentWindow.print();
		}
	}

	private createPrintIframe() {

		let payload = {
			gameName: this.gameName,
			draws: this.data
		};

		const drawStats = btoa(JSON.stringify(payload));
		const iframeTarget: HTMLElement = this.printDrawStatsIframeDiv.nativeElement;

		let existingIframe = <HTMLIFrameElement> document.getElementById('draw-history-iframe');

		let iframe = existingIframe || document.createElement('iframe');

		iframe.style.opacity = '0';
		iframe.style.visibility = 'hidden';
		iframe.id = 'draw-history-iframe';
		iframe.src = `${window.location.origin}/#/print-draw-stats/${drawStats}`;
		iframe.style.position = 'absolute';

		if (!existingIframe) {
			this.iframeElement = iframeTarget.appendChild(iframe);
		}
	}

	changePageSize($event) {
		this.selectedSize = $event;

		if (this.pageSize !== this.selectedSize[0].size) {
			this.pageSize = this.selectedSize[0].size;
			this.pageNumber = 0;
			this.getGamePastDraws();
			this.createPrintIframe();
		}
	}

}
