import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppLangService } from 'src/app/app-header';
import { AgentBalanceService, AuthService } from 'src/app/shared/services';

@Component({
  selector: 'ra-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  constructor(private agentBalanceService: AgentBalanceService,
    private appLangService: AppLangService,
    private authService: AuthService,
    private route: ActivatedRoute) { }

  newBalance: number;
  currencyCode: string;
  localeID: string;

  ngOnInit() {

    this.localeID = this.appLangService.selectedLocaleID;
    this.currencyCode = this.authService.authTokenData.payload.CurrencyCode;    

    this.route.params.subscribe(params => {
      let newBalance = params["newBalance"];      

      if (newBalance) {
        this.agentBalanceService.balance = newBalance;
        this.newBalance = newBalance;
      }      
    });

  }



}
