<div class="overlay-content">
	<div class="header">
		{{ 'scanned-ticket.title' | translate:{'gameName': scannedTicket.gameName} }}
		-
		{{ scannedTicket.ticketCost | currency:scannedTicket.currencyCode:'symbol':'1.0-2':localeID | hgReplaceCurrencyCode:scannedTicket.currencyCode }}
		<button class="close-btn" (click)="closeOverlay()"></button>
	</div>

	<div class="content">
		<div class="comment">
			<!-- <h3>{{ 'purchase-print.customer-name' | translate }}</h3> -->
			<p>{{ scannedTicket.comment }}</p>
		</div>

		<div class="participations-list" *ngIf="scannedTicket.participations.length !== undefined">
			<ng-container *ngFor="let p of scannedTicket.participations; let i = index">
				<ra-ticket-participation [class.first-participation]="p.ticketEntryNum === 1" [participationData]="p"
										 [ticketEntryNumber]="p.ticketEntryNum"></ra-ticket-participation>
			</ng-container>
		</div>
	</div>

	<div class="footer">
		<hr>

		<div class="tot-winnings">
			<ng-container *ngIf="hasParticipationsToPay">
				<b>{{ 'scanned-ticket.tot-winnings-lbl' | translate }}</b> {{ scannedTicket.totalWinnings | currency:scannedTicket.currencyCode:'symbol':'1.0-2':localeID | hgReplaceCurrencyCode:scannedTicket.currencyCode }}
			</ng-container>
			<ng-container *ngIf="!hasParticipationsToPay">
				<b>{{ 'scanned-ticket.no-winnings' | translate }}</b>
			</ng-container>
		</div>

		<button *ngIf="cancelTicketEnabled" class="default-btn" [class.is-loading]="isCancelLoading"
				(click)="cancelTicketConfirm()" [disabled]="!cancelTicketEnabled">
			<span>{{ 'scanned-ticket.cancel-ticket-btn' | translate }}</span>
		</button>

		<button class="default-btn" [class.is-loading]="isMarkAsPaidLoading" (click)="markAsPaid()"
				[disabled]="!hasParticipationsToPay">
			<span>{{ 'scanned-ticket.mark-as-paid-btn' | translate }}</span>
		</button>
	</div>
</div>
