<h1>{{ 'install-app.title' | translate }}</h1>

<p>
	{{ 'install-app.message' | translate }}
	<br><br>
	<i>{{ 'install-app.note' | translate }}</i>
</p>
<hr>
<p>
	<button class="default-btn" (click)="installApp()">
		{{ 'install-app.install-btn' | translate }}
	</button>
</p>