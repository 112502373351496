import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {Game, GetGamesResponse} from '../models/game';
import {ServiceActions} from '../service-actions.urls';
import {AuthService} from './auth.service';
import {AppConfigService} from 'helio-games-core';

@Injectable()
export class GamesService {
	private getGamesURL: string;

	constructor(
		private http: HttpClient,
		private appConfigService: AppConfigService,
		private authService: AuthService,
	) {
	}

	getGames(currencyCode?: string, countryCode?: string): Observable<Game[]> {
		this.getGamesURL = `${this.appConfigService.serviceBaseURL}${ServiceActions.GET_GAME_GROUPS}`;
		const headers = new HttpHeaders().set('Content-Type', 'application/json');

		let params = new HttpParams()
			.set('apiKey', this.authService.authTokenData.payload.APIKey);

		if (currencyCode !== undefined) {
			params = params
				.set('currencyCode', currencyCode);
		}

		if (countryCode !== undefined) {
			params = params
				.set('countryCode', countryCode);
		}

		return this.http.get<GetGamesResponse>(this.getGamesURL, {headers: headers, params: params})
			.pipe(
				map(response => {
					return response.gameGroups;
				})
			);
	}
}
