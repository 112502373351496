import { Component, HostBinding, ViewChild, AfterViewInit, OnInit, HostListener } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { ComponentHostDirective } from './shared/directives';
import { AppLayoutService, AppOverlaysService, AuthService, WindowEventsService } from './shared/services';

@Component({
	selector: 'ra-app',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
	@HostBinding('attr.app-version') appVersion; // = VERSION;
	@HostBinding('class.is-mobile') isMobile = false;
	@ViewChild('overlayHost', { static: true, read: ComponentHostDirective }) overlayHost: ComponentHostDirective;

	isLoading = false;
	secondsInactive = 0;
	INACTIVITY_TIMEOUT_SECONDS = 300;

	constructor(
		private appOverlaysService: AppOverlaysService,
		private appLayoutService: AppLayoutService,
		private windowEventsService: WindowEventsService,
		private authService: AuthService,
		private router: Router
	) {
		this.appVersion = require('package.json').version;
	}

	ngOnInit() {
		this.setHtmlFontSize();

		this.windowEventsService.onResize
			.subscribe(() => this.setHtmlFontSize());

		this.startCheckingForActivity();

		this.appLoadingHandler();
	}

	ngAfterViewInit() {
		this.appOverlaysService.overlayHost = this.overlayHost;
	}

	private appLoadingHandler() {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.isLoading = true;
			} else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
				this.isLoading = false;
			}
		});
	}

	private setHtmlFontSize() {
		const windowWidth = window.innerWidth;
		const html = document.getElementsByTagName('html')[0];
		const fontSize = this.appLayoutService.getFontSize(windowWidth);

		this.appLayoutService.isMobile = (windowWidth < 887);
		this.isMobile = this.appLayoutService.isMobile;

		html.style.fontSize = `${fontSize}%`;
	}

	@HostListener('document:touchstart', [])
	onTouchStart = async (): Promise<void> => {
		this.secondsInactive = 0;
	}

	@HostListener('document:mousemove', [])
	onMouseMove = async (): Promise<void> => {
		this.secondsInactive = 0;
	}

	@HostListener('click', [])
	onMouseClick = async (): Promise<void> => {
		this.secondsInactive = 0;
	}

	private startCheckingForActivity = () => {
		setInterval(() => {
			this.secondsInactive++;

			if (this.secondsInactive > this.INACTIVITY_TIMEOUT_SECONDS) {
				this.authService.logout();
			}

		}, 1000);
	}
}
