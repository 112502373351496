import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { AgentPlaceBetService } from 'helio-games-core';

import { AuthService, AgentBalanceService } from '../../shared/services';

@Injectable()
export class CancelTicketService {

	constructor(
		private authService: AuthService,
		private agentPlaceBetService: AgentPlaceBetService,
		private agentBalanceService: AgentBalanceService
	) { }

	cancelTicket(couponID: string) {
		const authHeaderValue = `${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`;
		return this.agentPlaceBetService.cancelCoupon(couponID, authHeaderValue)
			.pipe(
				map(res => {
					if (res.isSuccess) {
						this.agentBalanceService.balance = res.balance.balance;
					}
					return res.isSuccess;
				}),
				catchError(() => {
					return of(false);
				})
			);
	}
}
