<div class="print-container">
    <div class="header">
        <h3>-- {{'app-menu.sales-stats' | translate}} --</h3>
        <h4>{{'sales-stats.printout.generated-lbl' | translate}}: {{printData.timestamp | date:'short'}}</h4>
    </div>

    <div class="agent-info">
        <h4>{{agentName}} (ID: {{agentId}})</h4>
    </div>

    <div class="date-range">
        <h4>{{printData.from | date:'short'}} - {{printData.to | date:'short'}}</h4>
    </div>

    <div class="readout">
        <hr />

        <table>
            <thead>
                <tr>
                    <th>{{'sales-stats.printout.game-lbl' | translate}}</th>
                    <th>{{'sales-stats.printout.sold-lbl' | translate}}</th>
                    <th>{{'sales-stats.printout.payouts-lbl' | translate}}</th>
                    <th>{{'sales-stats.printout.cancelled-lbl' | translate}}</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of printData.items">
                    <td>{{getGameInitials(item.game)}}</td>
                    <td>{{item.sold | currency:currency:'symbol':'1.0-2':localeID}}</td>
                    <td>{{item.paid | currency:currency:'symbol':'1.0-2':localeID}}</td>
                    <td>{{(item.cancelled || 0) | currency:currency:'symbol':'1.0-2':localeID}}</td>
                </tr>
            </tbody>

            <tfoot>
                <tr>                    
                    <td colspan="4"><hr/></td>                    
                </tr>
                <tr>
                    <td>TOT</td>
                    <td>{{totalSold | currency:currency:'symbol':'1.0-2':localeID}}</td>
                    <td>{{totalPaid | currency:currency:'symbol':'1.0-2':localeID}}</td>
                    <td>{{(totalCancelled || 0) | currency:currency:'symbol':'1.0-2':localeID}}</td>
                </tr>
            </tfoot>
        </table>

        <hr />
    </div>

    <div class="ending-balance">
        <h3>{{'sales-stats.printout.ending-balance-lbl' | translate}}: {{endingBalance | currency:currency:'symbol':'1.0-2':localeID}}</h3>
    </div>
</div>