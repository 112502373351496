import { Component, OnInit } from '@angular/core';
import { PwaService } from '../shared/services';

@Component({
	selector: 'ra-install-app',
	templateUrl: './install-app.component.html',
	styleUrls: ['./install-app.component.scss']
})
export class InstallAppComponent implements OnInit {

	constructor(
		private pwaService: PwaService
	) { }

	ngOnInit() {
	}

	installApp() {
		this.pwaService.promptEvent.prompt();
	}

}
