import { Component, Input, HostBinding, OnInit, OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { AgentPlaceBetService } from 'helio-games-core';

import { ScannedTicket, ScannedTicketParticipation } from '../shared/models';
import { appOverlaysAnimations } from '../../app-overlays.animations';
import { AlertComponent } from '../../alert';
import { ErrorHandlerService, AuthService, AppOverlaysService, AgentBalanceService } from '../../shared/services';
import { AppLangService } from '../../app-header';
import { CancelTicketService } from './cancel-ticket.service';
import { CapabilitiesService } from 'src/app/shared/services/capabilities.service';

@Component({
	selector: 'ra-scanned-ticket',
	templateUrl: './scanned-ticket.component.html',
	styleUrls: ['./scanned-ticket.component.scss'],
	animations: appOverlaysAnimations
})
export class ScannedTicketComponent implements OnInit, OnDestroy {

	@HostBinding('@fade') fade = true;
	@Input() scannedTicket: ScannedTicket;

	cancelTicketEnabled = false;
	hasParticipationsToPay = false;

	localeID: string;

	isCancelLoading = false;
	isMarkAsPaidLoading = false;

	private now = new Date();
	private participationsToPay: ScannedTicketParticipation[] = [];

	private intervalTimer: any;

	constructor(
		private agentPlaceBetService: AgentPlaceBetService,
		private agentBalanceService: AgentBalanceService,
		private appOverlaysService: AppOverlaysService,
		private authService: AuthService,
		private errorHandlerService: ErrorHandlerService,
		private translateService: TranslateService,
		private appLangService: AppLangService,
		private cancelTicketService: CancelTicketService,
		private capabilities: CapabilitiesService
	) { }

	ngOnInit() {
		this.localeID = this.appLangService.selectedLocaleID;

		let canCancel = this.capabilities.getCapability('cancel');

		this.cancelTicketEnabled = canCancel && (this.now <= this.scannedTicket.cancellationLimit);
		if (this.cancelTicketEnabled) {
			this.intervalTimer = setInterval(() => {
				this.now = new Date();
				this.cancelTicketEnabled = this.now <= this.scannedTicket.cancellationLimit;

				if (!this.cancelTicketEnabled) {
					clearInterval(this.intervalTimer);
				}
			}, 1000);
		}

		this.participationsToPay = this.getParticipationsToPay();
		this.hasParticipationsToPay = (this.participationsToPay.length > 0);
	}

	ngOnDestroy() {
		if (this.intervalTimer !== undefined) {
			clearInterval(this.intervalTimer);
		}
	}

	closeOverlay() {
		this.appOverlaysService.removeOverlay();
	}

	markAsPaid() {
		if (this.participationsToPay.length > 0 && !this.isMarkAsPaidLoading) {
			this.isMarkAsPaidLoading = true;
			const participationIDs = this.participationsToPay.map(p => p.participationID);

			const authHeaderValue = `${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`;

			this.agentPlaceBetService.participationPayout(
				this.scannedTicket.couponID,
				authHeaderValue,
				this.scannedTicket.totalWinnings,
				...participationIDs

			).subscribe(res => {
				this.isMarkAsPaidLoading = false;
				if (res.isSuccess) {
					this.agentBalanceService.balance = res.balance.balance;
					this.updateAfterMarkAsPaid(participationIDs);

					this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
						alertComponent.type = 'info';
						alertComponent.contentText = this.translateService.instant('scanned-ticket.messages.winnings-paid-success');
					});
				} else {
					this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
						alertComponent.type = 'error';
						alertComponent.contentText = this.translateService.instant('scanned-ticket.messages.winnings-paid-error');
					});
				}
			}, error => {
				this.isMarkAsPaidLoading = false;
				this.errorHandlerService.handleError(error, this.translateService.instant('scanned-ticket.messages.winnings-paid-error'));
			});
		}
	}

	cancelTicketConfirm() {
		this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
			alertComponent.type = 'info';
			alertComponent.contentText = this.translateService.instant('scanned-ticket.cancel-ticket-confirm');

			alertComponent.hasConfirmBtn = true;
			alertComponent.confirmBtnText = this.translateService.instant('general.alerts.yes-btn');
			alertComponent.confirmBtnClick.subscribe(() => {
				this.cancelTicket();
				this.appOverlaysService.removeOverlay();
			});

			alertComponent.closeBtnText = this.translateService.instant('general.alerts.no-btn');
		});
	}

	private cancelTicket() {
		if (!this.isCancelLoading) {
			this.isCancelLoading = true;

			this.cancelTicketService.cancelTicket(this.scannedTicket.couponID)
				.subscribe(isSuccess => {
					this.isCancelLoading = false;
					if (isSuccess) {
						this.cancelTicketEnabled = false;
						if (this.intervalTimer !== undefined) {
							clearInterval(this.intervalTimer);
						}

						this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
							alertComponent.type = 'info';
							alertComponent.contentText = this.translateService.instant('scanned-ticket.messages.ticket-cancel-success');
						});
					} else {
						this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
							alertComponent.type = 'error';
							alertComponent.contentText = this.translateService.instant('scanned-ticket.messages.ticket-cancel-error');
						});
					}
				}, error => {
					this.isCancelLoading = false;
					this.errorHandlerService.handleError(error, this.translateService.instant('scanned-ticket.messages.ticket-cancel-error'));
				});
		}
	}

	private updateAfterMarkAsPaid(participationIDs: number[]) {
		const participationsToUpdate = this.scannedTicket.participations.filter(p => {
			return participationIDs.indexOf(p.participationID) !== -1;
		});

		participationsToUpdate.forEach(p => {
			p.isPaidOut = true;
		});

		this.scannedTicket.totalWinnings = 0;
		this.hasParticipationsToPay = false;
	}

	private getParticipationsToPay(): ScannedTicketParticipation[] {
		return this.scannedTicket.participations.filter(p => {
			return p.isDrawClosed && p.isPaidOut === false && p.winAuthorisationStatus === 0;
		});
	}

}
