import { Dictionary } from 'helio-games-core';

export const pageFontSize: Dictionary<HtmlPageFontSize> = new Dictionary<HtmlPageFontSize>();

// Default
pageFontSize.add(80, {
	pageWidthMM: '80mm',
	fontSizePercentage: '40%'
});

pageFontSize.add(58, {
	pageWidthMM: '58mm',
	fontSizePercentage: '40%'
});

export interface HtmlPageFontSize {
	pageWidthMM: string;
	fontSizePercentage: string;
}
