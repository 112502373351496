import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameService, GameGroup,  } from 'helio-games-core';
import { BuyTicketService } from './buy-ticket.service';
import { BuyTicketPageData } from './buy-ticket-page-data.model';
import { AppLayoutService } from '../shared/services';
import { CouponPickerComponent } from './coupon-picker';

@Component({
	selector: 'ra-buy-ticket',
	templateUrl: './buy-ticket.component.html',
	styleUrls: ['./buy-ticket.component.scss']
})
export class BuyTicketComponent implements OnInit, OnDestroy {
	currentGameGroup: GameGroup;
	mobilePickNumbers = false;
	isMobile = false;

	@ViewChild('scrollTo', { static: false }) couponPicker: CouponPickerComponent;

	constructor(
		private route: ActivatedRoute,
		private buyTicketService: BuyTicketService,
		private gameService: GameService,
		private appLayoutService: AppLayoutService,
	) { }

	ngOnInit() {
		this.currentGameGroup = this.gameService.getGame();

		const gameData: BuyTicketPageData = this.route.snapshot.data['buyTicketPageData'];
		this.buyTicketService.buyTicketGameData = gameData;

		this.mobilePickNumbers = this.buyTicketService.isMobilePickNumbers;
		this.buyTicketService.pickNumbersChange.subscribe((isMobilePickNumbers) => {
			this.mobilePickNumbers = isMobilePickNumbers;
		});

		this.isMobile = this.appLayoutService.isMobile;
		this.appLayoutService.onMobileVersionChange.subscribe(isMobile => {
			this.isMobile = isMobile;
		});
	}

	ngOnDestroy() {
		if (this.buyTicketService.ticketSelectedItems.length > 0) {
			this.buyTicketService.ticketSelectedItems = [];
		}
	}

	scrollIntoView() {
		setTimeout(() => {
			this.couponPicker.scrollToElement.nativeElement.scrollIntoView({behavior: 'smooth', block: 'end'});
		}, 0);
	}
}
