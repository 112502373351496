export interface Language {
	name: string;
	code: string;
}

export const langConfig: Language[] = [
	{
		name: 'English',
		code: 'en'
	},
	{
		name: 'Farsi',
		code: 'fa'
	},
	{
		name: 'French',
		code: 'fr'
	}
];
