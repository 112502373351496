import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { AgentCouponsService } from 'helio-games-core';

import { appOverlaysAnimations } from '../../app-overlays.animations';
import { AuthService, ErrorHandlerService, AppOverlaysService } from '../../shared/services';
import { AlertComponent } from '../../alert';
import { ScannedTicket } from '../shared/models';
import { ScannedTicketComponent } from '../scanned-ticket';

@Component({
	selector: 'ra-scan-ticket',
	templateUrl: './scan-ticket.component.html',
	styleUrls: ['./scan-ticket.component.scss'],
	animations: appOverlaysAnimations
})
export class ScanTicketComponent implements OnInit, OnDestroy {

	@HostBinding('@fade') fade = true;

	multipleCameras = false;
	deviceCameras: MediaDeviceInfo[] = [];
	selectedDevice: MediaDeviceInfo[] = [];

	currentDevice: MediaDeviceInfo = null;
	deviceEnabled = false;

	noDevicesFound = false;
	cancelBtnEnabled = false;

	constructor(
		private appOverlaysService: AppOverlaysService,
		private couponsService: AgentCouponsService,
		private authService: AuthService,
		private errorHandlerService: ErrorHandlerService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
	}

	ngOnDestroy() {
		this.deviceEnabled = false;
	}

	setCurrentCamera(camera: MediaDeviceInfo) {
		if (this.currentDevice === null) {
			this.currentDevice = camera;
			this.selectedDevice = [this.currentDevice];
		}
	}

	camerasFoundHandler(deviceCameras: MediaDeviceInfo[]) {
		this.noDevicesFound = false;
		this.deviceEnabled = true;

		this.deviceCameras = deviceCameras;
		this.multipleCameras = (this.deviceCameras.length > 1);
	}

	camerasNotFoundHandler() {
		this.noDevicesFound = true;
	}

	cameraSelectionChange(deviceCamera: MediaDeviceInfo[]) {
		this.selectedDevice = [deviceCamera[0]];
		this.currentDevice = this.selectedDevice[0];
	}

	scanSuccessHandler(couponID: string) {
		this.appOverlaysService.removeOverlay();
		this.couponsService.getCoupon(couponID, `${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`)
			.subscribe(agentCouponRes => {
				if (agentCouponRes.isSuccess) {
					const scannedTicket = new ScannedTicket(agentCouponRes.coupon, agentCouponRes.cancellationIntervalSeconds);
					this.createScannedTicketOverlay(scannedTicket);
				} else {
					this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
						alertComponent.type = 'error';
						alertComponent.contentText = this.translateService.instant('general.errors.ticket-not-found');
					});
				}
			}, error => {
				this.errorHandlerService.handleError(error);
			});
	}

	closeScanTicket() {
		this.appOverlaysService.removeOverlay();
	}

	private createScannedTicketOverlay(scannedTicket: ScannedTicket) {
		this.appOverlaysService.loadOverlay(ScannedTicketComponent, (componentInstance: ScannedTicketComponent) => {
			componentInstance.scannedTicket = scannedTicket;
		});
	}

}
