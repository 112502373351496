<div class="wrapper">
	<h2>{{ 'scan-ticket.title' | translate }}</h2>
	<ng-container *ngIf="multipleCameras">
		<p>
			{{ 'scan-ticket.select-camera-lbl' | translate }}
			<hg-dropdown
				[list]="deviceCameras"
				[labelProp]="'label'"
				[listSelection]="selectedDevice"
				(listSelectionChange)="cameraSelectionChange($event)">
			</hg-dropdown>
		</p>
	</ng-container>

	<zxing-scanner
		[enable]="deviceEnabled"
		[device]="currentDevice"
		(deviceChange)="setCurrentCamera($event)"
		(camerasFound)="camerasFoundHandler($event)"
		(camerasNotFound)="camerasNotFoundHandler()"
		(scanSuccess)="scanSuccessHandler($event)"
		[formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']">
	</zxing-scanner>

	<ng-container *ngIf="noDevicesFound === true && deviceCameras.length === 0">
		{{ 'scan-ticket.no-cameras' | translate }}
	</ng-container>

	<footer>
		<button class="default-btn cancel" (click)="closeScanTicket()">
			{{ 'general.alerts.cancel-btn' | translate }}
		</button>
	</footer>
</div>