import DomToImage from 'dom-to-image';
import {saveAs} from 'file-saver';
import {Component, OnInit, Input, HostBinding, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {appOverlaysAnimations} from '../../../app-overlays.animations';
import {AuthService, AppOverlaysService, ErrorHandlerService} from '../../../shared/services';
import {AlertComponent} from '../../../alert';
import {CancelTicketService} from '../../../check-ticket/scanned-ticket';
import {BuyTicketService} from '../../buy-ticket.service';
import {CapabilitiesService} from 'src/app/shared/services/capabilities.service';
import {GameFinancialRulePrizeCategory, GameFinancialRulesService, GameService} from 'helio-games-core';
import * as _ from 'lodash';
import {CouponToPrint} from './coupon.model';

@Component({
	selector: 'ra-print-ticket',
	templateUrl: './print-ticket.component.html',
	styleUrls: ['./print-ticket.component.scss'],
	animations: appOverlaysAnimations
})
export class PrintTicketComponent implements OnInit, AfterViewInit {

	canShare = true;
	canReprint = false;
	canCancel = true;

	agentID: string;
	tenantName: string;
	comment: string;
	reprints = 0;

	isCancelLoading = false;
	isPrinting = false;

	@HostBinding('@fade') fade = true;

	/**
	 * This input is set via {@link PurchasePrintComponent#createPrintTicketOverlay}
	 */
	@Input() couponToPrint: CouponToPrint;

	hasClickedPrintBtn = false;
	hasClickedShareBtn = false;

	@ViewChild('printIframeDiv', {static: false}) printIframeDiv: ElementRef;

	private iframeElement: HTMLIFrameElement;

	constructor(
		private appOverlaysService: AppOverlaysService,
		private authService: AuthService,
		private translateService: TranslateService,
		private cancelTicketService: CancelTicketService,
		private errorHandlerService: ErrorHandlerService,
		private buyTicketService: BuyTicketService,
		private capabilities: CapabilitiesService,
		private gameService: GameService,
		private gameFinancialRulesService: GameFinancialRulesService
	) {
	}

	ngOnInit() {
		this.canShare = this.capabilities.getCapability('share');
		this.canReprint = this.capabilities.getCapability('reprint');
		this.canCancel = this.capabilities.getCapability('cancel');
	}

	ngAfterViewInit() {
		// this.createPrintIframe();
	}

	async print(): Promise<void> {
		if (!this.isPrinting) {
			this.isPrinting = true;
			this.hasClickedPrintBtn = false;

			const filename = `ticket - ${this.couponToPrint.tickets[0].friendlyCouponIdentifier}.png`;
			const printContent = document.getElementById('ticket-to-print');

			if (printContent) {
				// Calculate the height of the content in millimeters
				const contentHeightPx = printContent.offsetHeight;
				// since desired width is known. However, to full match this height with actual printed height,
				// it is necessary that the displayed UI font size matches that of the printed
				const contentWidthPx = (printContent.querySelector('.address') as HTMLElement).offsetWidth;
				const scale = contentWidthPx / 80;
				const contentHeightMm = contentHeightPx / scale;
				// console.log('contentHeightPx:', contentHeightPx, '-- contentHeightMm: ', contentHeightMm);

				const WindowPrt = window.open('', '', 'width=900,height=650');
				if (WindowPrt) {
					// Get the styles from the original document
					const styles = Array.from(document.styleSheets)
						.map(styleSheet => {
							try {
								return Array.from(styleSheet.cssRules)
									.map(rule => rule.cssText)
									.join('');
							} catch (e) {
								console.error(e);
								return '';
							}
						})
						.join('');

					const printSize = '80mm ' + (contentHeightMm * 1.8) + 'mm'; // '80mm 60mm';
					WindowPrt.document.write('<html><head><title>' + filename + '</title>');
					WindowPrt.document.write('<style>@media print { body { margin: 0; height: 100%; width: 100%; } }</style>');
					WindowPrt.document.write('<style>@media print { @page { margin: 0; size:' + printSize + ';} }</style>');
					WindowPrt.document.write('<style>body, html { margin: 0; padding: 0; height: auto; width: 80mm; overflow: hidden; }</style>');
					WindowPrt.document.write('<style>body html div { display: flex; justify-content: center; align-items: center; }</style>');
					WindowPrt.document.write('<style>img { width: 100%; height: 100%; object-fit: contain; }</style>');
					WindowPrt.document.write('<style>' + styles + '</style>'); // Add the styles
					WindowPrt.document.write('</head><body>');
					WindowPrt.document.write(printContent.innerHTML); // Insert the HTML content
					WindowPrt.document.write('</body></html>');

					WindowPrt.document.close();

					// Delay the print to ensure content is fully loaded
					setTimeout(() => {
						WindowPrt.focus();
						WindowPrt.print();
						WindowPrt.close();

						this.isPrinting = false;
						this.hasClickedPrintBtn = true;
					}, 500);
				} else {
					console.error('Failed to open new window for printing.');
					this.isPrinting = false;
				}
			} else {
				console.error('Printing node not found.');
				this.isPrinting = false;
			}
		}
	}

	share() {
		const node = document.getElementById('ticket-to-print');
		DomToImage.toBlob(node)
			.then((blob) => {
				const filename = `ticket - ${this.couponToPrint.tickets[0].friendlyCouponIdentifier}.png`;

				const file = new File([blob], filename, {type: 'image/png'});
				const filesArray = [file];

				const navigator = (window.navigator as any);

				if (navigator.share) {
					if (navigator.canShare && navigator.canShare({files: filesArray})) {
						navigator.share({
							text: ` Your ${this.couponToPrint.tickets[0].gameName} Ticket`,
							files: filesArray,
							title: `${this.couponToPrint.tickets[0].tenantName} - Lottery Ticket`
						});
					}
				} else {
					saveAs(blob, filename);
				}

				this.hasClickedShareBtn = true;
			});
	}

	cancelTicketConfirm() {
		this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
			alertComponent.type = 'info';
			alertComponent.contentText = this.translateService.instant('scanned-ticket.cancel-ticket-confirm');

			alertComponent.hasConfirmBtn = true;
			alertComponent.confirmBtnText = this.translateService.instant('general.alerts.yes-btn');
			alertComponent.confirmBtnClick.subscribe(() => {
				this.cancelTicket();
				this.appOverlaysService.removeOverlay();
			});

			alertComponent.closeBtnText = this.translateService.instant('general.alerts.no-btn');
		});
	}

	closePrintTicket() {
		if (this.hasClickedPrintBtn || this.hasClickedShareBtn) {
			this.appOverlaysService.removeOverlay();
		}
	}

	public getMaxWin = (): number => {
		let currentTotal = this.getMaxWinFromSelectedTickets();

		const tickets = this.couponToPrint.tickets;
		const gameTiers = _.uniq(tickets.map(t => t.gameTier));
		const financialRules = gameTiers.map(t => this.getGameFinancialRuleFromTierName(t));

		const currentFinancialRule = this.buyTicketService.buyTicketGameData.gameFinancialRule;

		if (!financialRules.find(t => t.gameFinancialRuleID === currentFinancialRule.gameFinancialRuleID)) {
			currentTotal += this.getMaxWinFromFinancialRule(currentFinancialRule.gameFinancialRuleID);
		}

		return currentTotal;
	}

	public getMaxWinFromFinancialRule = (financialRuleID: number = undefined): number => {

		const financialRule = financialRuleID === undefined
			? this.buyTicketService.buyTicketGameData.gameFinancialRule
			: this.gameFinancialRulesService.gameFinancialRules.find(f => f.gameFinancialRuleID === financialRuleID);

		let jackpots: GameFinancialRulePrizeCategory;
		let maxPrize = 0;

		if (financialRule.prizeCategories.length > 0) {
			jackpots = financialRule.prizeCategories.find(p => p.prizeCategoryID === 1);

			if (jackpots !== undefined) {
				const prize = jackpots.amounts.find(p => p.currencyCode === this.couponToPrint.tickets[0].currencyCode);

				maxPrize = prize.prizeAmount == null
					? prize.prizeAmount
					: prize.minPrizeAmount;
			}
		}

		return maxPrize;
	}

	public getMaxWinFromSelectedTickets = (): number => {
		const tickets = this.couponToPrint.tickets;
		const gameTiers = _.uniq(tickets.map(t => t.gameTier));
		const financialRules = gameTiers.map(t => this.getGameFinancialRuleFromTierName(t));

		return financialRules.reduce((total, rule) => {
			const jackpot = rule.prizeCategories.find(entry => entry.prizeCategoryID === 1);
			const jackpotInSelectedCurrency = jackpot.amounts.find(j => j.currencyCode === this.couponToPrint.tickets[0].currencyCode);

			const prize = jackpotInSelectedCurrency.prizeAmount == null
				? jackpotInSelectedCurrency.prizeAmount
				: jackpotInSelectedCurrency.minPrizeAmount;

			return total + prize;
		}, 0);
	}

	private cancelTicket() {
		if (!this.isCancelLoading) {
			this.isCancelLoading = true;

			this.cancelTicketService.cancelTicket(this.couponToPrint.tickets[0].couponIdentifier)
				.subscribe(isSuccess => {
					this.isCancelLoading = false;
					if (isSuccess) {
						this.appOverlaysService.removeOverlay();
						this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
							alertComponent.type = 'info';
							alertComponent.contentText = this.translateService.instant('scanned-ticket.messages.ticket-cancel-success');
						});
					} else {
						this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
							alertComponent.type = 'error';
							alertComponent.contentText = this.translateService.instant('scanned-ticket.messages.ticket-cancel-error');
						});
					}
				}, error => {
					this.isCancelLoading = false;
					this.errorHandlerService.handleError(error, this.translateService.instant('scanned-ticket.messages.ticket-cancel-error'));
				});
		}
	}

	private getGameFinancialRuleFromTierName = (tierName: string) => {
		const game = this.gameService.games.find(g => g.name === tierName);
		return this.gameFinancialRulesService.gameFinancialRules.find(gfr => gfr.gameID === game.gameID);
	}

}
