import {Component, OnInit} from '@angular/core';
import {AuthService, AgentBalanceService, AppLayoutService} from '../shared/services';
import {CapabilitiesService} from '../shared/services/capabilities.service';
import {DepositService} from '../shared/services/deposit.service';
import {AppLangService} from './app-lang-select';
import {RetailAgentService} from '../shared/services/retail-agent.service';
import {Subscription} from 'rxjs';

@Component({
	selector: 'ra-app-header',
	templateUrl: './app-header.component.html',
	styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

	agentFullName: string;
	agentID: string;
	currencyCode: string;
	paymentGateway: string;
	idempotencyKey: string;
	depositing: boolean;
	redirecting: boolean;
	balance = 0;

	now: Date;

	localeID: string;

	isMobile: boolean;
	languages: string[];

	getActualBalance$: Subscription;

	constructor(
		private authService: AuthService,
		private agentBalanceService: AgentBalanceService,
		private appLangService: AppLangService,
		private depositService: DepositService,
		private appLayoutService: AppLayoutService,
		private capabilities: CapabilitiesService,
		private agentService: RetailAgentService
	) {
	}

	ngOnInit() {
		this.localeID = this.appLangService.selectedLocaleID;
		this.agentFullName = this.authService.authTokenData.payload.FullName;
		this.agentID = this.authService.authTokenData.payload.EntityID;
		this.currencyCode = this.authService.authTokenData.payload.CurrencyCode;
		this.paymentGateway = this.authService.authTokenData.payload.PaymentGateway;

		const caps = JSON.parse(this.authService.authTokenData.payload.Capabilities);
		this.capabilities.setCapabilities(caps);

		this.languages = this.getAvailableLanguages(this.capabilities.getCapability('languages'));

		this.balance = this.agentBalanceService.balance;
		this.agentBalanceService.onAgentBalanceChange.subscribe(newBalance => {
			this.balance = newBalance;

			// To make sure bal is aligned with API after UI carries out manual computation
			this.getActualBalance();
		});

		// This is call is necessary here too, to refresh the balance on page reload
		this.getActualBalance();

		this.isMobile = this.appLayoutService.isMobile;

		this.now = new Date();

		setInterval(() => {
			this.now = new Date();
		}, 1000);

		if (this.paymentGateway) {
			this.depositService.getIdempotencyKey()
				.then(data => {
					this.idempotencyKey = data;
				});
		}
	}

	getActualBalance() {
		if (this.getActualBalance$) {
			this.getActualBalance$.unsubscribe();
			this.getActualBalance$ = null;
		}

		this.getActualBalance$ = this.agentService.getActualBalance().subscribe({
			next: (balance) => {
				this.balance = balance;
			},
			error: (err) => {
				console.warn('getActualBalance: balance err =', err);
			}
		});
	}

	deposit() {

		if (!this.paymentGateway) {
			return;
		}

		this.depositing = true;

		this.depositService.createDeposit(this.paymentGateway, this.currencyCode, this.idempotencyKey)
			.then(data => {
				this.depositing = false;
				this.redirecting = true;
				window.location.href = data.url;
			});
	}

	getAvailableLanguages = (langs: string): string[] => {
		return langs.split(',');
	}

	logout() {
		this.authService.logout();
	}

}
