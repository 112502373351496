import {Injectable, isDevMode} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LogService {

	/**
	 * Log a message to the console.
	 * @param message The message to log.
	 */
	static log(message: string): void {
		console.log(`[LOG]: ${message}`);
	}

	/**
	 * Log a message to the console only in development mode.
	 * @param message The message to log.
	 */
	static devLog(message: string): void {
		if (isDevMode()) {
			console.log(`[LOG]: ${message}`);
		}
	}

	/**
	 * Log a warning message to the console.
	 * @param message The message to warn.
	 */
	static warn(message: string): void {
		console.warn(`[WARN]: ${message}`);
	}

	/**
	 * Log an error message to the console only in development mode.
	 * @param message The error message to log.
	 * @param error The error to log.
	 */
	static devError(message: string, error: any): void {
		if (isDevMode()) {
			console.error(`[DEV ERROR]: ${message}`, error);
		}
	}
}
