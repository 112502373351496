import { Component, OnInit } from '@angular/core';
import { NavRoute } from './nav-route.model';
import { PwaService } from '../shared/services';

@Component({
	selector: 'ra-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

	navRoutes: NavRoute[] = [
		{
			name: 'app-menu.buy-tickets',
			route: '/home'
		},
		{
			name: 'app-menu.draw-stats',
			route: '/draw-stats'
		},
		{
			name: 'app-menu.sales-stats',
			route: '/sales-stats'
		},
		{
			name: 'app-menu.printer-setup',
			route: '/printer-setup'
		}
	];

	constructor(
		private pwaService: PwaService
	) { }

	ngOnInit() {
		if (this.pwaService.promptEvent !== undefined) {
			this.navRoutes.push({
				name: 'app-menu.install-app',
				route: '/install-app'
			});
		}
	}

}
