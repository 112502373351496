import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../shared/services';

@Component({
	selector: 'ra-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	loginForm: FormGroup;
	isLoginLoading = false;
	loginFailed = false;

	constructor(
		private authService: AuthService,
		private formBuilder: FormBuilder,
		private router: Router
	) { }

	ngOnInit() {
		this.initForm();
	}

	login() {
		if (!this.isLoginLoading) {
			this.isLoginLoading = true;

			const username = this.loginForm.get('username').value;
			const password = this.loginForm.get('password').value;

			this.authService.login(username, password)
				.subscribe((res) => {
					this.isLoginLoading = false;
					if (res !== null) {
						this.router.navigate(['home']);
					} else {
						this.loginFailed = true;
					}
				}, error => {
					this.isLoginLoading = false;
					this.loginFailed = true;
				});
		}
	}

	private initForm() {
		this.loginForm = this.formBuilder.group({
			username: new FormControl('', Validators.required),
			password: new FormControl('', Validators.required)
		});
	}

}
