import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
	BaseService, AppConfigService, RequestHeaders, Dictionary, ServiceAction
} from 'helio-games-core';

import { AuthService } from '../shared/services';
import {ServiceController} from '../shared/service-controller.urls';

@Injectable()
export class ChangePasswordService extends BaseService {
	constructor(
		protected http: HttpClient,
		private appConfigService: AppConfigService,
		private authService: AuthService
	) {
		super(http, ServiceController.RETAIL_AGENT, appConfigService.agentServiceBaseURL);
	}

	changePassword(oldPassword: string, newPassword: string) {
		const requestHeaders: RequestHeaders = {
			headersValues: new Dictionary({
				'Authorization': `${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`
			}),
			appendToDefaultHeaders: true
		};

		const data = {
			oldPassword: oldPassword,
			newPassword: newPassword
		};

		return this.edit(ServiceAction.CHANGE_PASSWORD, data, undefined, requestHeaders);

	}
}
