import { registerLocaleData } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'helio-games-core';
import { environment } from '../environments/environment';
import { AppLangService } from './app-header';
import { PwaService } from './shared/services';
import { CapabilitiesService } from './shared/services/capabilities.service';

let languageCodeToLoad: string;

export function appInitLoader(
	appConfigService: AppConfigService,
	appLangService: AppLangService,
	translateService: TranslateService,
	capabilities: CapabilitiesService,
	pwaService: PwaService
) {
	return async () => {
		beforeInstallPromptHandler(pwaService);

		await appConfigLoader(appConfigService);

		await loadLanguage(appConfigService, translateService, appLangService);

		await dynamicLanguageLoad(appConfigService, appLangService);

		await addHelioGamesJavascriptLibrary();

		return Promise.resolve();
	};
}

function beforeInstallPromptHandler(pwaService: PwaService) {
	window.addEventListener('beforeinstallprompt', (e) => {
		e.preventDefault();
		pwaService.promptEvent = e;
	});
}

function appConfigLoader(appConfigService: AppConfigService) {

	return new Promise<void>(resolve => {
		let gameServer = '';
		let tenantServer = getTenantServer();
		tenantServer = (tenantServer == '0')? environment.defaultTenant : tenantServer;

			switch (environment.env) {
				case 'prod':
					gameServer = `prod-${tenantServer}`;
					break;
				case 'stage':
					gameServer = `stg-${tenantServer}`;
					break;
				case 'qa':
					gameServer = `qa-${tenantServer}`;
					break;
				case 'dev-server':
					gameServer = `dev-1`;
					break;
				case 'dev':
				default:
					gameServer = 'stg-1';
					break;
		}

		appConfigService.loadAppConfig(gameServer)
			.then(() => resolve());
	});

}

// get tenant server, and if no tenant server is found, then return a default of 1
function getTenantServer(): string {
	const currentURL = window.location.href;
	const regex = /-ts([0-9]+)./;
	if (currentURL.match(regex) != null) {
		return currentURL.match(regex)[1];
	} else {
		return '0';
	}
}

function loadLanguage(appConfigService: AppConfigService, translateService: TranslateService, appLangService: AppLangService) {
	return new Promise<void>(resolve => {
		translateService.setDefaultLang('en');
		languageCodeToLoad = localStorage.getItem('agentAppLang');

		if (appConfigService.supportedLanguages === undefined) {
			console.warn(`App Config does not specify supportedLanguages. Game Language will default to English.`);
			languageCodeToLoad = 'en';
		} else if (languageCodeToLoad !== null && languageCodeToLoad !== undefined && languageCodeToLoad !== '') {
			languageCodeToLoad = getSupportedLanguageCode(languageCodeToLoad, appConfigService.supportedLanguages);
		} else {
			languageCodeToLoad = 'en';
		}

		if (languageCodeToLoad !== 'en') {
			translateService.use(languageCodeToLoad)
				.subscribe(() => {
					// console.log('load language successful');
				}, error => {
					console.log('load language failed');
					console.log(error);
					// load default language
					translateService.use('en').subscribe(() => {
						languageCodeToLoad = 'en';
						resolve();
					});
				}, () => {
					appLangService.selectedLanguage = languageCodeToLoad;
					resolve();
				});
		} else {
			// load default language
			translateService.use('en').subscribe(() => {
				languageCodeToLoad = 'en';
				appLangService.selectedLanguage = languageCodeToLoad;
				resolve();
			});
		}
	});
}

function dynamicLanguageLoad(appConfigService: AppConfigService, appLangService: AppLangService) {
	return new Promise<void>(resolve => {
		let localeID = languageCodeToLoad;

		if (appConfigService.supportedLanguages === undefined) {
			console.warn(`App Config does not specify supportedLanguages. Game Locale will default to en-GB.`);
		} else {
			localeID = getSupportedLanguageCode(localeID, appConfigService.supportedLanguages);
		}

		if (localeID !== 'en') {
			import(`node_modules/@angular/common/locales/${localeID}.mjs`)
				.then(importedModule => {
					registerLocaleData(importedModule.default, localeID);
					appLangService.selectedLocaleID = localeID;
				}).catch(() => {
					loadDefaultLocale(appLangService);
				}).then(() => {
					resolve();
				});
		} else {
			loadDefaultLocale(appLangService);
			resolve();
		}
	});
}

function loadDefaultLocale(appLangService: AppLangService) {
	import(`node_modules/@angular/common/locales/en-GB.mjs`)
		.then(importedModule => {
			registerLocaleData(importedModule.default, 'en-GB');
			appLangService.selectedLocaleID = 'en-GB';
		});
}

function getSupportedLanguageCode(gameConfigLanguageCode: string, supportedLanguages: string[]): string {
	let isLanguageSupported = supportedLanguages.indexOf(gameConfigLanguageCode) !== -1;

	if (isLanguageSupported) {
		return gameConfigLanguageCode;
	}

	if (!isLanguageSupported && gameConfigLanguageCode.length > 2) {
		const tempLanguageCode = gameConfigLanguageCode.substring(0, 2);
		isLanguageSupported = supportedLanguages.indexOf(tempLanguageCode) !== -1;

		if (isLanguageSupported) {
			return tempLanguageCode;
		}
	}

	return 'en';
}

function addHelioGamesJavascriptLibrary() {
	return new Promise<void>(resolve => {
		const helioScript = document.createElement('script');
		helioScript.type = 'text/javascript';
		helioScript.src = environment.helioGameJavaScriptUrl;
		document.body.appendChild(helioScript);
		helioScript.onload = () => {
			resolve();
		};
	});
}
