import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { appOverlaysAnimations } from '../app-overlays.animations';
import { BuyTicketService } from '../buy-ticket';
import { AppOverlaysService } from '../shared/services';

@Component({
	selector: 'ra-comment-modal',
	templateUrl: './comment-modal.component.html',
	styleUrls: ['./comment-modal.component.scss'],
	animations: [appOverlaysAnimations]
})
export class CommentModalComponent implements OnInit {

	commentText: string;
	@Output() comment = new EventEmitter<void>();
	@ViewChild('commentInput', { static: false }) commentInput: ElementRef;

	constructor(
		private buyTicketService: BuyTicketService, 
		private appOverlaysService: AppOverlaysService
	) { }

	ngOnInit() {}

	ngAfterViewInit() {
		this.commentInput.nativeElement.focus();
	}

	onCommentUpdate(comment) {	
		this.commentText = comment;	
		this.buyTicketService.comment = comment;
	}

	cancel() {
		this.buyTicketService.isQuickBuyReady = false;
		this.appOverlaysService.removeOverlay();
	}

	buy() {
		this.comment.next();
	}
}
