import { AgentCoupon, TicketEntry, Participation } from 'helio-games-core';
import { ScannedTicketParticipation } from './scanned-ticket-participation.model';
import { ScannedTicketParticipationNumber } from './scanned-ticket-participation-number.model';

export class ScannedTicket {
	couponID: string;
	currencyCode: string;
	gameName: string;
	totalWinnings = 0;
	timestamp: Date;
	ticketCost = 0;
	comment: string;

	participations: ScannedTicketParticipation[] = [];

	cancellationLimit: Date;

	constructor(scannedAgentCoupon: AgentCoupon, cancellationLimit: number) {
		this.couponID = scannedAgentCoupon.couponIdentifier;
		this.currencyCode = scannedAgentCoupon.currencyCode;
		this.gameName = scannedAgentCoupon.tickets[0].gameName;
		this.timestamp = new Date(scannedAgentCoupon.timestamp);
		this.ticketCost = scannedAgentCoupon.tickets[0].ticketCost;
		this.comment = scannedAgentCoupon.comment;

		this.setCancellationDate(cancellationLimit);

		this.saveScannedTicketData(scannedAgentCoupon);
	}

	private saveScannedTicketData(agentCoupon: AgentCoupon) {
		const ticketEntries: TicketEntry[] = [];
		agentCoupon.tickets.forEach(ticket => ticketEntries.push(...ticket.ticketEntries));

		ticketEntries.forEach(entry => {
			// save participation details
			const ticketParticipations = entry.participations.map(p => {
				return this.getParticipationDetails(p, entry);
			});

			this.participations.push(...ticketParticipations);
		});

		this.participations.sort((a, b) => a.drawNumber - b.drawNumber);
	}

	private getParticipationDetails(participation: Participation, ticketEntry: TicketEntry): ScannedTicketParticipation {
		const isDrawClosed = (participation?.draw?.drawStatusID === 2 || participation?.draw?.drawStatusID === 3);

		const isDrawResultPending = (isDrawClosed && (participation?.draw?.results === null || participation?.draw?.results === undefined));

		let drawResultNumbers: string[][] = [];
		if (isDrawClosed && !isDrawResultPending) {
			const drawResultBoards = participation?.draw?.results?.split('|');
			drawResultNumbers = drawResultBoards.map(b => b.split(','));
		}

		let winAmount = 0;
		let winAuthorisationStatus = 0;
		if (participation?.winnings !== undefined && participation?.winnings !== null) {
			winAmount = participation?.winnings?.amount;
			winAuthorisationStatus = participation?.winnings?.authorisationStatusID;

			if (!participation?.winnings?.paidOut && winAuthorisationStatus === 0) {
				this.totalWinnings += winAmount;
			}
		}

		let isPaidOut: boolean;
		if (participation?.winnings !== undefined) {
			isPaidOut = participation?.winnings?.paidOut;
		}

		return {
			participationID: participation?.participationID,
			currencyCode: this.currencyCode,
			drawDate: new Date(participation?.draw?.drawDate),
			drawResult: drawResultNumbers,
			drawNumber: participation?.draw?.drawNumber,
			isDrawClosed: isDrawClosed,
			isDrawResultPending: isDrawResultPending,
			selectedNumbers: this.getParticipationNumbers(ticketEntry, drawResultNumbers),
			winAmount: winAmount,
			isPaidOut: isPaidOut,
			winAuthorisationStatus: winAuthorisationStatus,
			ticketEntryNum: ticketEntry.ticketEntryNum
		};
	}

	private getParticipationNumbers(ticketEntry: TicketEntry, drawResult: string[][]): ScannedTicketParticipationNumber[][] {
		const boards = ticketEntry.numbers.split('|');
		const boardNumbers = boards.map(board => board.split(','));

		const hasDrawResults = drawResult.length > 0;

		return boardNumbers.map((board, i) => {
			return board.map<ScannedTicketParticipationNumber>(boardNum => {
				return {
					number: boardNum,
					isMatched: hasDrawResults && (drawResult[i].indexOf(boardNum) !== -1)
				};
			});
		});
	}

	private setCancellationDate(cancellationLimit: number) {
		this.cancellationLimit = new Date(this.timestamp);
		this.cancellationLimit = new Date(this.cancellationLimit.setSeconds(this.cancellationLimit.getSeconds() + cancellationLimit));
	}
}
