import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, Subscription } from 'rxjs';

import { Game, GameFinancialRule, GameFinancialRulesService, GameService, TicketSelectedItems } from 'helio-games-core';

import { BuyTicketPageData } from './buy-ticket-page-data.model';

@Injectable({ providedIn: 'root' })
export class BuyTicketService {
	ticketSelectedItems: TicketSelectedItems[] = [];

	reprints = new BehaviorSubject<number>(0);

	private _reprints = 0;

	private _comment: string;
	private commentSubject = new Subject<string>();

	private consecutiveDrawsValue = 1;
	private isQuickBuyReadyValue = false;
	private isMobilePickNumbersValue = false;
	
	private buyTicketGameDataValue: BuyTicketPageData;
	private gameFinancialRuleChangeSubject = new Subject<GameFinancialRule>();
	private ticketSelectedItemsChangeSubject = new Subject<void>();
	private consecutiveDrawsChangeSubject = new Subject<void>();
	private isQuickBuyReadyChangeSubject = new Subject<boolean>();
	private addRandomSubject = new Subject<void>();
	private pickNumbersSubject = new Subject<boolean>();	

	private selectedGameSubscription: Subscription;

	constructor(
		private gameService: GameService,
		private gameFinancialRulesService: GameFinancialRulesService) {
		
		this.selectedGameSubscription = this.gameService.selectedGameChange.subscribe((game) => {			
			this.buyTicketGameDataValue.gameFinancialRule = this.gameFinancialRulesService.gameFinancialRules.find(gfr => gfr.gameID == game.gameID);
			this.gameFinancialRuleChangeSubject.next(this.buyTicketGameDataValue.gameFinancialRule);
		});

	}	

	addPickedItems(newPickedItems: TicketSelectedItems): void {
		this.ticketSelectedItems.push(newPickedItems);
		this.ticketSelectedItemsChangeSubject.next();
	}

	removePickedItems(index?: number): void {
		if (index === undefined) {
			this.ticketSelectedItems.splice(0);
		} else {
			this.ticketSelectedItems.splice(index, 1);
		}

		this.ticketSelectedItemsChangeSubject.next();
	}



	get ticketSelectedItemsChange(): Observable<void> {
		return this.ticketSelectedItemsChangeSubject.asObservable();
	}

	get consecutiveDrawsChange(): Observable<void> {
		return this.consecutiveDrawsChangeSubject.asObservable();
	}

	get isQuickBuyReadyChange(): Observable<boolean> {
		return this.isQuickBuyReadyChangeSubject.asObservable();
	}

	get gameFinancialRuleChange(): Observable<GameFinancialRule> {
		return this.gameFinancialRuleChangeSubject.asObservable();
	}

	get commentUpdate(): Observable<string> {
		return this.commentSubject.asObservable();
	}

	get addRandomChange() {
		return this.addRandomSubject.asObservable();
	}

	get pickNumbersChange() {
		return this.pickNumbersSubject.asObservable();
	}

	get buyTicketGameData() {
		return this.buyTicketGameDataValue;
	}

	set buyTicketGameData(newBuyTicketGameData: BuyTicketPageData) {
		this.buyTicketGameDataValue = newBuyTicketGameData;
	}

	get consecutiveDraws(): number {
		return this.consecutiveDrawsValue;
	}

	set consecutiveDraws(newConsecutiveDrawsValue: number) {
		this.consecutiveDrawsValue = newConsecutiveDrawsValue;

		this.consecutiveDrawsChangeSubject.next();
	}

	get isQuickBuyReady(): boolean {
		return this.isQuickBuyReadyValue;
	}

	set isQuickBuyReady(newIsQuickBuyReadyValue: boolean) {
		this.isQuickBuyReadyValue = newIsQuickBuyReadyValue;

		this.isQuickBuyReadyChangeSubject.next(this.isQuickBuyReadyValue);
	}

	get comment(): string {
		return this._comment;
	}

	set comment(c: string) {
		this._comment = c;
		this.commentSubject.next(c);
	}

	addRandom() {
		this.addRandomSubject.next();
	}

	get isMobilePickNumbers() {
		return this.isMobilePickNumbersValue;
	}

	set isMobilePickNumbers(isMobilePickNumbers: boolean) {
		this.isMobilePickNumbersValue = isMobilePickNumbers;
		this.pickNumbersSubject.next(isMobilePickNumbers);
	}

	reprint() {
		this._reprints++;
		this.reprints.next(this._reprints);
		localStorage.setItem('ticket-reprints', this._reprints.toFixed(0));
	}

	resetReprints() {
		this._reprints = 0;
		this.reprints.next(this._reprints);
		localStorage.setItem('ticket-reprints', '0');
	}
}
