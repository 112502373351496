import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'raFormatDrawResult'
})
export class FormatDrawResultPipe implements PipeTransform {
	transform(value: string) {
		if (value === undefined) {
			return '';
		}

		return value.replace(/,/g, ', ').replace(/\|/g, ' + ');
	}
}
