import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { GameService, GameGroupHierarchy, GameRulesService, GameConfigService, GameFinancialRulesService } from 'helio-games-core';
import { BuyTicketPageData } from './buy-ticket-page-data.model';
import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BuyTicketResolver implements Resolve<BuyTicketPageData> {
	constructor(
		private gameService: GameService,
		private gameConfigService: GameConfigService,
		private gameRulesService: GameRulesService,
		private gameFinancialRulesService: GameFinancialRulesService
	) { }

	resolve(route: ActivatedRouteSnapshot): Observable<BuyTicketPageData> {
		const gameGroupCode = route.paramMap.get('gameGroupCode');
		const isQuickbuy = route.queryParamMap.get('isQuickbuy') === 'true';

		return this.gameService.getGameFromServer(gameGroupCode)
			.pipe(
				mergeMap((gameData: GameGroupHierarchy) => {
					this.gameConfigService.gameConfig.gameGroupCode = gameGroupCode;

					return forkJoin(
						this.gameRulesService.getGameRules(),
						this.gameFinancialRulesService.getCurrentFinancialRulesFromServer()
					);
				})
			).pipe(
				map((data) => {
					const currentGame = this.gameService.currentGame;

					// get last cached game rule / game financial rule in service cache
					// slice is used since reverse is mutable, and reverse is used since find searches for the first match
					const gameRule = this.gameRulesService.gameRules.slice().reverse().find(tempGameRule => {
						return tempGameRule.gameGroupID === currentGame.gameGroupID;
					});

					this.gameFinancialRulesService.gameFinancialRules = data[1].slice().reverse();

					const gameFinancialRule = data[1].slice().reverse().find(tempFinancialRule => {
						return tempFinancialRule.gameID === currentGame.gameID;
					});

					return {
						gameGroupCode: gameGroupCode,
						isQuickbuy: isQuickbuy,
						gameRule: gameRule,
						gameFinancialRule: gameFinancialRule
					};
				})
			);
	}
}
