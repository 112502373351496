import DomToImage from 'dom-to-image';

import {Component, OnInit, ViewChild, OnDestroy, Output, ElementRef, EventEmitter} from '@angular/core';

import {bindNodeCallback, Subscription} from 'rxjs';

import {
	BuyTicketData,
	GameService,
	TicketSelectedItems,
	BuyTicketMultiDraws,
	GameUtilities,
	GameFinanicalRulePrice,
	BuyButtonEmitData,
	AgentPlaceBetResponse,
	GameGroup,
	GameRuleBoard,
	GameFinancialRulesService,
	AgentCoupon,
	GameFinancialRulePrizeCategory,
	GameRulePrizeCategory, LoginService
} from 'helio-games-core';

import {TranslateService} from '@ngx-translate/core';

import {BuyTicketService} from '../buy-ticket.service';
import {PurchasedTicket, PrintTicketComponent} from './print-ticket';
import {AuthService, AppOverlaysService, ErrorHandlerService, AgentBalanceService} from '../../shared/services';
import {AppLangService} from '../../app-header';
import {DrawDropdownItem, DRAW_DROPDOWN_ITEMS} from '../coupon-picker/draw-dropdown.data';
import {AlertComponent} from '../../alert';
import {CommentModalComponent} from 'src/app/comment-modal';
import {CapabilitiesService} from 'src/app/shared/services/capabilities.service';
import {GameDropdownItem} from '../coupon-picker/game-dropdown.data';
import * as _ from 'lodash';
import {CouponToPrint} from './print-ticket/coupon.model';
import {BuyButtonComponent} from '../buy-button/buy-button.component';

@Component({
	selector: 'ra-purchase-print',
	templateUrl: './purchase-print.component.html',
	styleUrls: ['./purchase-print.component.scss']
})
export class PurchasePrintComponent implements OnInit, OnDestroy {

	comment = '';
	betRequiresComment: boolean;
	showCommentModal = false;

	currentGameGroup: GameGroup;
	gameRuleBoards: GameRuleBoard[];
	emptyPicker: any[][];

	tenantName: string;

	ticketSelectedItems: TicketSelectedItems[] = [];
	ticketCost = 0;

	linePrice = 0;
	maxWin = 0;
	canShowMaxWin = false;

	currencyCode = '';

	consecutiveDraws = 1;

	purchasedTickets: PurchasedTicket[];

	games: GameDropdownItem[];
	selectedGames: GameDropdownItem[] = [];

	@ViewChild('buyButton', {static: false}) buyButtonComponent: BuyButtonComponent;
	@Output() scrollIntoView = new EventEmitter();

	isBetLoading = false;

	localeID: string;
	isQuickBuyReady: boolean;

	consecutiveDrawsItems: DrawDropdownItem[] = DRAW_DROPDOWN_ITEMS;
	selectedConsecutiveDraws: DrawDropdownItem[] = [DRAW_DROPDOWN_ITEMS[0]];

	multiBetsAllowed = false;
	comboBetsAllowed = false;
	combinations: number;

	private ticketSelectItemsSubscription: Subscription;
	private consecutiveDrawsSubscription: Subscription;
	private gameFinancialRuleChangeSubscription: Subscription;
	private isQuickBuyReadySubscription: Subscription;
	private commentUpdateSubscription: Subscription;

	constructor(
		private authService: AuthService,
		private gameService: GameService,
		private gameFinancialRulesService: GameFinancialRulesService,
		private buyTicketService: BuyTicketService,
		private appOverlaysService: AppOverlaysService,
		private errorHandlerService: ErrorHandlerService,
		private agentBalanceService: AgentBalanceService,
		private appLangService: AppLangService,
		private translateService: TranslateService,
		private capabilities: CapabilitiesService
	) {
	}

	ngOnInit() {
		this.currentGameGroup = this.gameService.getGame();
		this.localeID = this.appLangService.selectedLocaleID;

		this.currencyCode = this.authService.authTokenData.payload.CurrencyCode;
		this.ticketSelectedItems = this.buyTicketService.ticketSelectedItems;

		this.games = this.gameService.games.map(g => {
			return {
				gameID: g.gameID,
				tier: g.name,
				multiplier: g.multiplier,
				isDefault: g.isDefault
			} as GameDropdownItem;
		}).sort((g1, g2) => g1.multiplier - g2.multiplier);

		this.selectedGames = [this.games[0]];

		this.linePrice = this.getLinePrice();
		this.maxWin = this.getMaxWin();
		this.canShowMaxWin = this.capabilities.getCapability('showMaxWin');

		this.setEmptyPickerArray(this.buyTicketService.buyTicketGameData.gameRule.boards);

		this.tenantName = this.authService.authTokenData.payload.TenantName;

		this.betRequiresComment = this.authService.authTokenData.payload.BetRequiresComment;

		this.ticketSelectItemsSubscription = this.buyTicketService.ticketSelectedItemsChange.subscribe(() => {
			this.refreshTicketCost();
		});

		this.consecutiveDrawsSubscription = this.buyTicketService.consecutiveDrawsChange.subscribe(() => {
			this.consecutiveDraws = this.buyTicketService.consecutiveDraws;
			this.selectedConsecutiveDraws = [this.consecutiveDrawsItems.find(d => d.numOfDraws === this.consecutiveDraws)];
			this.refreshTicketCost();
		});

		this.gameFinancialRuleChangeSubscription = this.buyTicketService.gameFinancialRuleChange.subscribe(game => {

			this.linePrice = this.getLinePrice();
			this.refreshTicketCost();
			this.maxWin = this.getMaxWin();
		});

		this.isQuickBuyReadySubscription = this.buyTicketService.isQuickBuyReadyChange.subscribe(isReady => {
			this.isQuickBuyReady = isReady;

			if (isReady) {

				if (this.betRequiresComment) {
					this.appOverlaysService.loadOverlay(CommentModalComponent, (commentModalComponent: CommentModalComponent) => {
						commentModalComponent.comment.subscribe(c => {
							this.comment = this.buyTicketService.comment;
							this.appOverlaysService.removeOverlay();

							this.purchaseAndPrint();
							this.buyTicketService.isQuickBuyReady = false;
							this.isQuickBuyReady = false;
						});
					});
				} else {
					this.purchaseAndPrint();
					this.buyTicketService.isQuickBuyReady = false;
					this.isQuickBuyReady = false;
				}
			}
		});

		this.gameService.selectedGameChange.subscribe((g) => {
			this.selectedGames = [{
				gameID: g.gameID,
				tier: g.name,
				multiplier: g.multiplier,
				isDefault: g.isDefault
			} as GameDropdownItem];
		});

		const gameRule = this.buyTicketService.buyTicketGameData.gameRule;
		this.gameRuleBoards = gameRule.boards;

		this.comboBetsAllowed = this.capabilities.getCapability('comboBets');
		this.multiBetsAllowed = this.capabilities.getCapability('multiBets');
	}

	ngOnDestroy() {
		if (this.ticketSelectItemsSubscription !== undefined) {
			this.ticketSelectItemsSubscription.unsubscribe();
		}

		if (this.consecutiveDrawsSubscription !== undefined) {
			this.consecutiveDrawsSubscription.unsubscribe();
		}

		if (this.isQuickBuyReadySubscription !== undefined) {
			this.isQuickBuyReadySubscription.unsubscribe();
		}
	}

	remove(index: number) {
		this.buyTicketService.removePickedItems(index);
	}

	removeAllPickedItems() {
		this.buyTicketService.removePickedItems();
	}

	consecutiveDrawsChange(consecutiveDraws: DrawDropdownItem[]) {
		this.buyTicketService.consecutiveDraws = consecutiveDraws[0].numOfDraws;
	}

	purchaseAndPrint() {
		if (this.isBetLoading) {
			return;
		}

		this.isBetLoading = true;
		const enoughBal = this.hasEnoughBalance(this.ticketCost);

		if (enoughBal) {
			const multiDrawsObj = new BuyTicketMultiDraws();
			multiDrawsObj.multiDraws = this.consecutiveDraws;

			const bets: BuyTicketData[] = [];

			this.buyTicketService.ticketSelectedItems.forEach((ticket: TicketSelectedItems, index: number) => {

				const gameFinancialRule = this.getGameFinancialRuleFromTierName(ticket.tierName);

				const ticketPrices = gameFinancialRule.ticketPrices.length > 1
					? gameFinancialRule.ticketPrices.find(t => t.isDefault)
					: gameFinancialRule.ticketPrices[0];

				const linePrice = +GameUtilities.getLinePrice(ticketPrices.amounts, 'amount', this.currencyCode);

				const betData = new BuyTicketData(
					gameFinancialRule.gameID,
					[ticket],
					undefined,
					linePrice,
					index === 0,
					true,
					multiDrawsObj,
					[],
					undefined,
					0,
					undefined,
					false,
					undefined,
					true,
					false,
					`lottery`,
					`${this.authService.authTokenData.token_type} ${this.authService.authTokenData.access_token}`,
					this.comment
				);

				bets.push(betData);
			});

			this.buyButtonComponent.buy(bets, this.authService.isLoggedIn, enoughBal);
		} else {
			this.isBetLoading = false;
			this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
				alertComponent.type = 'error';
				alertComponent.contentText = this.translateService.instant('purchase-print.not-enough-balance');
			});
		}
	}

	onPurchaseAndPrintCallback(buyButtonEmit: BuyButtonEmitData) {
		this.isBetLoading = false;

		if (buyButtonEmit.isSuccessful) {
			this.agentBalanceService.balance = (buyButtonEmit.placeBetResponse as AgentPlaceBetResponse).balance.balance;

			this.purchasedTickets = this.getPurchasedTicketData(buyButtonEmit.placeBetResponse as AgentPlaceBetResponse);
			this.buyTicketService.resetReprints();

			this.createPrintTicketOverlay();
			this.resetCouponPicker();
		} else {
			// console.log('buyButtonEmit: error', buyButtonEmit?.error)
			// this.errorHandlerService.handleError(buyButtonEmit?.error, buyButtonEmit?.errorMsg);

			this.appOverlaysService.loadOverlay(AlertComponent, (alertComponent: AlertComponent) => {
				alertComponent.type = 'error';
				alertComponent.contentText = buyButtonEmit?.error['status'] + ': ' + buyButtonEmit?.errorMsg;
			});
		}
	}

	selectedGameChange(selectedGames: GameDropdownItem[]) {
		this.selectedGames = selectedGames;
		this.gameService.setCurrentGame(selectedGames[0].gameID);

		// this.buyTicketService.buyTicketGameData = {
		// 	gameGroupCode: this.gameService.currentGame.gameGroupCode,
		// 	isQuickbuy: false,
		// };
	}

	addRandom() {
		this.buyTicketService.addRandom();
	}

	pickNumbers() {
		this.buyTicketService.isMobilePickNumbers = true;
		this.scrollIntoView.emit();
	}

	onCommentUpdate(comment) {
		this.comment = comment;
		this.buyTicketService.comment = comment;
	}

	public getLinePrice(): number {
		const financialRule = this.buyTicketService.buyTicketGameData.gameFinancialRule;
		let ticketPrices: GameFinanicalRulePrice;

		if (financialRule.ticketPrices.length > 0) {
			ticketPrices = financialRule.ticketPrices.find(t => t.isDefault);

			if (ticketPrices === undefined) {
				ticketPrices = financialRule.ticketPrices[0];
			}
		}

		return +GameUtilities.getLinePrice(ticketPrices.amounts, 'amount', this.currencyCode);
	}

	public getMaxWin = (): number => {
		let currentTotal = this.getMaxWinFromSelectedTickets();

		const tickets = this.ticketSelectedItems;
		const gameTiers = _.uniq(tickets.map(t => t.tierName));
		const financialRules = gameTiers.map(t => this.getGameFinancialRuleFromTierName(t));

		const currentFinancialRule = this.buyTicketService.buyTicketGameData.gameFinancialRule;

		if (!financialRules.find(t => t.gameFinancialRuleID === currentFinancialRule.gameFinancialRuleID)) {
			currentTotal += this.getMaxWinFromFinancialRule(currentFinancialRule.gameFinancialRuleID);
		}

		return currentTotal;
	}

	public getMaxWinFromFinancialRule = (financialRuleID: number = undefined): number => {

		const financialRule = financialRuleID === undefined
			? this.buyTicketService.buyTicketGameData.gameFinancialRule
			: this.gameFinancialRulesService.gameFinancialRules.find(f => f.gameFinancialRuleID === financialRuleID);

		let jackpots: GameFinancialRulePrizeCategory;
		let maxPrize = 0;

		if (financialRule.prizeCategories.length > 0) {
			jackpots = financialRule.prizeCategories.find(p => p.prizeCategoryID === 1);

			if (jackpots !== undefined) {
				const prize = jackpots.amounts.find(p => p.currencyCode === this.currencyCode);

				maxPrize = prize.prizeAmount == null
					? prize.prizeAmount
					: prize.minPrizeAmount;
			}
		}

		return maxPrize;
	}

	public getMaxWinFromSelectedTickets = (): number => {
		const tickets = this.ticketSelectedItems;
		const gameTiers = _.uniq(tickets.map(t => t.tierName));
		const financialRules = gameTiers.map(t => this.getGameFinancialRuleFromTierName(t));

		return financialRules.reduce((total, rule) => {
			const jackpot = rule.prizeCategories.find(entry => entry.prizeCategoryID === 1);
			const jackpotInSelectedCurrency = jackpot.amounts.find(j => j.currencyCode === this.currencyCode);

			const prize = jackpotInSelectedCurrency.prizeAmount == null
				? jackpotInSelectedCurrency.prizeAmount
				: jackpotInSelectedCurrency.minPrizeAmount;

			return total + prize;
		}, 0);
	}

	private setEmptyPickerArray(gameRuleBoards: GameRuleBoard[]) {
		this.emptyPicker = gameRuleBoards.map(b => {
			return new Array(b.numColumnsCoupon);
		});
	}

	private createPrintTicketOverlay() {
		this.appOverlaysService.loadOverlay(PrintTicketComponent, (componentInstance: PrintTicketComponent) => {
			componentInstance.couponToPrint = {
				maxWin: this.maxWin,
				tickets: this.purchasedTickets
			} as CouponToPrint;
		});
	}

	private resetCouponPicker() {
		this.buyTicketService.removePickedItems();
		this.buyTicketService.consecutiveDraws = 1;
	}

	private refreshTicketCost() {
		this.ticketCost = this.getBetTotalCost();
		this.combinations = this.getBetCombinationSize();
		this.maxWin = this.getMaxWin();
	}

	private getBetCombinationSize = () => {

		let result = 0;
		const tickets = this.ticketSelectedItems;

		tickets.forEach((ticket) => {
			result += this.getTicketCombinationSize(ticket);
		});

		return result;
	}

	private getTicketCombinationSize = (ticket: TicketSelectedItems): number => {
		let combinations = 1;

		const boards = ticket.selectedItems;

		boards.forEach((b, index) => {
			const couponSize = b.pickedNumbers.length;
			const normalColumnAmount = this.gameRuleBoards[index].numColumnsCoupon;

			if (couponSize > normalColumnAmount) {
				combinations *= this.getNumberOfCombinations(couponSize, normalColumnAmount);
			}
		});

		return combinations;
	}

	private getBetTotalCost = () => {

		let cost = 0;

		const tickets = this.ticketSelectedItems;

		tickets.forEach((ticket) => {
			cost = cost + this.getTicketTotalCost(ticket);
		});

		return cost;
	}

	/**
	 * @summary copied from {@link GameUtilities.getLinePrice}
	 */
	private getLinePriceHelper(amountsList: any[], property: string, currencyCode: string | number): number {
		for (const amount of amountsList) {
			if (currencyCode === amount?.currencyCode) {
				return amount[property];
			} else if (String(currencyCode) === String(amount?.currencyID)) {
				return amount[property];
			}
		}

		console.error('getLinePriceHelper: amount not FOUND');
	}

	private getTicketTotalCost = (ticket: TicketSelectedItems): number => {
		const financialRule = this.getGameFinancialRuleFromTierName(ticket.tierName);

		const ticketPrices = financialRule.ticketPrices.length > 1
			? financialRule.ticketPrices.find(t => t.isDefault)
			: financialRule.ticketPrices[0];

		const linePrice = this.getLinePriceHelper(ticketPrices.amounts, 'amount', this.currencyCode);
		const combinations = this.getTicketCombinationSize(ticket);

		return linePrice * this.consecutiveDraws * combinations;
	}

	private getNumberOfCombinations = (listSize: number, sample: number) => {
		return this.factorial(listSize) / (this.factorial(sample) * this.factorial(listSize - sample));
	}

	private getGameFinancialRuleFromTierName = (tierName: string) => {
		const game = this.gameService.games.find(g => g.name === tierName);
		return this.gameFinancialRulesService.gameFinancialRules.find(gfr => gfr.gameID === game.gameID);
	}

	private factorial = (num: number) => {
		let rval = 1;
		for (let i = 2; i <= num; i++) {
			rval = rval * i;
		}
		return rval;
	}

	private getPurchasedTicketData(placeBetResponse: AgentPlaceBetResponse): PurchasedTicket[] {
		// only one ticket can be bought from this component since a player can bet only on one game

		const purchasedTickets: PurchasedTicket[] = [];

		const coupon: AgentCoupon = placeBetResponse.coupon;
		const tickets = coupon.tickets;

		tickets.forEach(ticket => {
			let participations = ticket.ticketEntries[0].participations;

			const ticketCost = ticket.ticketCost;

			const drawsFromTo: number[] = [];
			let selectedNumbers: string[][][] = [];

			participations = participations.sort((a, b) => a.draw.drawNumber - b.draw.drawNumber);

			const firstDrawDate = new Date(participations[0].draw.drawDate);

			drawsFromTo.push(placeBetResponse.drawNumberFrom);

			if (placeBetResponse.drawNumberFrom !== placeBetResponse.drawNumberTo) {
				drawsFromTo.push(placeBetResponse.drawNumberTo);
			}

			selectedNumbers = ticket.ticketEntries.map(te => {
				const boards = te.numbers.split('|');
				return boards.map(board => board.split(','));
			});

			let friendlyCouponIdentifier = '';
			for (let i = 0; i < coupon.couponIdentifier.length; i += 4) {
				friendlyCouponIdentifier += `${coupon.couponIdentifier.substr(i, 4)} `;
			}

			const game = this.gameService.games.find(g => g.gameID === ticket.gameID);

			purchasedTickets.push({
				agentID: this.authService.authTokenData.payload.EntityID,
				termsAndConditions: this.authService.authTokenData.payload.TermsAndConditions,
				tenantName: this.tenantName,
				gameGroupCode: this.currentGameGroup.code,
				gameName: this.currentGameGroup.name,
				gameTier: game?.name,
				isDefaultGame: game?.isDefault,
				gameVertical: (ticket as any).gameVertical,
				currencyCode: coupon.currencyCode,
				timestamp: placeBetResponse.coupon.timestamp,
				couponID: coupon.couponIdentifier,
				couponIdentifier: coupon.couponIdentifier,
				friendlyCouponIdentifier: friendlyCouponIdentifier,
				ticketCost: ticketCost,
				ticketValidForDraws: this.consecutiveDraws,
				selectedNumbers: selectedNumbers,
				drawsFromTo: drawsFromTo,
				firstDrawDate: firstDrawDate,
				comment: this.buyTicketService.comment
			});
		});

		return purchasedTickets;
	}

	private hasEnoughBalance(ticketPrice: number) {
		return ticketPrice <= this.agentBalanceService.balance;
	}

}
