<div class="wrapper">
	<h2>{{ 'change-password.title' | translate }}</h2>
	<div class="form-rules-wrapper">
		<div>
			<form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
				<div>
					<label for="old-pass">{{ 'change-password.old-pass-lbl' | translate }}</label>
					<p-password id="old-pass" formControlName="oldPassword" autocomplete="new-password"
								[toggleMask]="true" [feedback]="false"></p-password>
				</div>

				<div>
					<label for="new-pass">{{ 'change-password.new-pass-lbl' | translate }}</label>
					<p-password id="new-pass" formControlName="newPassword" autocomplete="new-password"
								[toggleMask]="true" [feedback]="false"
								[class.invalid]="!changePasswordForm.get('newPassword').valid && !changePasswordForm.get('newPassword').pristine"></p-password>
				</div>

				<div>
					<label for="confirm-pass">{{ 'change-password.confirm-pass-lbl' | translate }}</label>
					<p-password id="confirm-pass" formControlName="newPasswordConfirm" autocomplete="new-password"
								[toggleMask]="true" [feedback]="false"
								[class.invalid]="!changePasswordForm.get('newPasswordConfirm').valid && !changePasswordForm.get('newPasswordConfirm').pristine"></p-password>
				</div>

				<button type="submit" class="default-btn" [class.is-loading]="isLoading"
						[disabled]="!changePasswordForm.valid">
					<span>{{ 'change-password.change-pass-btn' | translate }}</span>
				</button>
			</form>
		</div>

		<div class="password-rules" style="display: grid; grid-template-rows: min-content min-content 1fr;">
			<h3>{{ 'change-password.pass-rules' | translate }}</h3>

			<ul>
				<li>{{ 'change-password.password-rules.rule-1' | translate }}</li>
				<li>{{ 'change-password.password-rules.rule-2' | translate }}</li>
				<li>{{ 'change-password.password-rules.rule-3' | translate }}</li>
				<li>{{ 'change-password.password-rules.rule-4' | translate }}</li>
				<li>{{ 'change-password.password-rules.rule-5' | translate }}</li>
				<li>{{ 'change-password.password-rules.rule-6' | translate }}</li>
			</ul>

			<div style="display: grid;">
				<button type="submit" class="default-btn" style="justify-self: center; align-self: self-end;"
						[class.is-loading]="isLoading" (click)="onSwitchAccount()">Switch Account</button>
			</div>
		</div>
	</div>
</div>
