import {Component, OnInit, ViewChild, ViewChildren, QueryList, AfterViewInit, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {
	BoardComponent, CouponPickerComponent as HelioCouponPickerComponent, GameRule,
	GameFinancialRule, GameRuleBoard, BoardSelectedItems, TicketSelectedItems, GameService, GameGroup
} from 'helio-games-core';
import * as rfdc from 'rfdc';
import {CapabilitiesService} from 'src/app/shared/services/capabilities.service';
import {BuyTicketService} from '../buy-ticket.service';
import {DrawDropdownItem, DRAW_DROPDOWN_ITEMS} from './draw-dropdown.data';
import {GameDropdownItem} from './game-dropdown.data';

@Component({
	selector: 'ra-coupon-picker',
	templateUrl: './coupon-picker.component.html',
	styleUrls: ['./coupon-picker.component.scss']
})
export class CouponPickerComponent implements OnInit, AfterViewInit {

	currentGameGroup: GameGroup;
	boardNumsToSelect: number[] = [];
	maxBoardNumsToSelect: number[] = [];

	gameGroupCode = '';
	isQuickbuy = false;
	gameRule: GameRule;
	gameFinancialRule: GameFinancialRule;

	games: GameDropdownItem[];
	selectedGames: GameDropdownItem[] = [];

	gameBoards: GameRuleBoard[] = [];

	consecutiveDrawsItems: DrawDropdownItem[] = DRAW_DROPDOWN_ITEMS;
	consecutiveDraws: DrawDropdownItem[] = [DRAW_DROPDOWN_ITEMS[0]];

	@ViewChild('scrollToElement', {static: false}) scrollToElement: ElementRef;
	@ViewChild('couponPicker', {static: false}) couponPicker: HelioCouponPickerComponent;
	@ViewChildren('board') boards: QueryList<BoardComponent>;
	boardComponents: BoardComponent[] = [];

	minItemsSelected = false;

	allowComboBets = false;
	allowMultiBets = false;

	constructor(
		private router: Router,
		private buyTicketService: BuyTicketService,
		private gameService: GameService,
		private capabilities: CapabilitiesService
	) {
	}

	ngOnInit() {
		this.currentGameGroup = this.gameService.getGame();

		this.gameGroupCode = this.buyTicketService.buyTicketGameData.gameGroupCode;
		this.isQuickbuy = this.buyTicketService.buyTicketGameData.isQuickbuy;
		this.gameRule = this.buyTicketService.buyTicketGameData.gameRule;
		this.gameFinancialRule = this.buyTicketService.buyTicketGameData.gameFinancialRule;

		this.games = this.gameService.games.map(g => {
			return {
				gameID: g.gameID,
				tier: g.name,
				multiplier: g.multiplier,
				isDefault: g.isDefault
			} as GameDropdownItem;
		}).sort((g1, g2) => g1.multiplier - g2.multiplier);

		this.selectedGames = [this.games[0]];

		this.gameBoards = this.gameRule.boards;
		this.boardNumsToSelect = this.gameRule.boards.map(b => b.numColumnsCoupon);
		this.maxBoardNumsToSelect = this.gameRule.boards.map(b => b.maxColumnBetAllowed);

		this.buyTicketService.consecutiveDrawsChange.subscribe(() => {
			this.consecutiveDraws = [this.consecutiveDrawsItems.find(d => d.numOfDraws === this.buyTicketService.consecutiveDraws)];
		});

		this.buyTicketService.addRandomChange.subscribe(() => {
			this.randomPick();
			this.addSelection();
		});

		this.gameService.selectedGameChange.subscribe((g) => {
			this.selectedGames = [{
				gameID: g.gameID,
				tier: g.name,
				multiplier: g.multiplier,
				isDefault: g.isDefault
			} as GameDropdownItem];
		});

		this.allowComboBets = this.capabilities.getCapability('comboBets');
		this.allowMultiBets = this.capabilities.getCapability('multiBets');
	}

	ngAfterViewInit() {
		this.boardComponents = this.boards.toArray();

		if (this.isQuickbuy) {
			this.quickBuy();
		}
	}

	pickNumber() {
		this.minItemsSelected = this.couponPicker.minItemsSelected;
	}

	addSelection() {
		if (this.minItemsSelected) {
			const boardsSelectedItems: BoardSelectedItems[] = this.boardComponents.map(tempBoard => {
				// returning deep copy of BoardSelectedItems object
				const boardObjClone: BoardSelectedItems = rfdc({proto: true})(tempBoard.selectedItems);
				const temp = new BoardSelectedItems(
					boardObjClone.boardItems,
					boardObjClone.boardData,
					boardObjClone.boardSelectionType
				);

				return temp;
			});

			this.buyTicketService.addPickedItems(
				new TicketSelectedItems(this.selectedGames[0].tier,
					this.selectedGames[0].isDefault, ...boardsSelectedItems)
			);

			this.couponPicker.clearSelections();

			if (this.buyTicketService.isMobilePickNumbers) {
				this.buyTicketService.isMobilePickNumbers = false;
			}
		}
	}

	clearSelection() {
		this.couponPicker.clearSelections();
	}

	randomPick() {
		this.couponPicker.quickpick(true);
	}

	consecutiveDrawsChange(consecutiveDraws: DrawDropdownItem[]) {
		this.consecutiveDraws = consecutiveDraws;
		this.buyTicketService.consecutiveDraws = this.consecutiveDraws[0].numOfDraws;
	}

	selectedGameChange(selectedGames: GameDropdownItem[]) {
		this.selectedGames = selectedGames;
		this.gameService.setCurrentGame(selectedGames[0].gameID);

		// this.buyTicketService.buyTicketGameData = {
		// 	gameGroupCode: this.gameService.currentGame.gameGroupCode,
		// 	isQuickbuy: false,
		// };
	}

	closeCouponPicker() {
		this.clearSelection();
		this.buyTicketService.isMobilePickNumbers = false;
	}

	private quickBuy() {
		setTimeout(() => {
			this.randomPick();
			this.addSelection();
			this.buyTicketService.isQuickBuyReady = true;

			this.router.navigate(['buy-ticket', this.gameGroupCode], {replaceUrl: true});
		});
	}
}
