import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AppConfigService } from 'helio-games-core';
import { CapabilitiesService } from 'src/app/shared/services/capabilities.service';
import { Language, langConfig } from './app-lang.config';
import { AppLangService } from './app-lang.service';

@Component({
	selector: 'ra-language-select',
	templateUrl: './app-lang-select.component.html',
	styleUrls: ['./app-lang-select.component.scss']
})
export class AppLangSelectComponent implements OnInit, AfterViewInit {

	languageList: Language[] = [];

	selectedLanguage: Language[] = [];

	constructor(
		private appLangService: AppLangService,
		private appConfigService: AppConfigService,
		private capabilities: CapabilitiesService
	) { }	

	ngOnInit() {

		let languages = langConfig;
		let allowedLanguages = this.capabilities.getCapability('languages').split(',');

		this.languageList = languages.filter(l => allowedLanguages.indexOf(l.code) > -1);

		const userLang = this.appLangService.selectedLanguage;
		if (userLang !== null) {
			const userLangIndex = this.languageList.findIndex(l => l.code === userLang);
			this.selectedLanguage = [this.languageList[userLangIndex]];
		} else {
			const englishIndex = this.languageList.findIndex(l => l.code === 'en');
			this.selectedLanguage = [this.languageList[englishIndex]];
		}					
	}

	ngAfterViewInit(): void {
		
	}

	languageChange(selectedLanguage: Language[]) {
		this.selectedLanguage = selectedLanguage;
		this.appLangService.selectedLanguage = selectedLanguage[0].code;

		window.location.reload();
	}

}
