import { Routes } from '@angular/router';
import { LoginComponent } from '../../login';
import { LoginGuard } from '../../shared/guards/login';

export const PUBLIC_ROUTES: Routes = [
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [LoginGuard]
	}
];
