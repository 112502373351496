<h1>{{ tenantName }}</h1>

<div class="content">
	<div class="game-logo-name">
		<div class="logo {{ currentGameGroup.code }}"></div>
		<div class="name">{{ currentGameGroup.name }}</div>
	</div>

	<ul *ngIf="ticketSelectedItems.length > 0">
		<li *ngFor="let lineSelectedItems of ticketSelectedItems; let i = index">
			<span class="line-number">{{ (65 + i) | numberToChar }}.</span>
			<ng-container *ngFor="let boardsSelectedItems of lineSelectedItems.selectedItems; let j = index">
				<span class="board-separator" *ngIf="j > 0"> + </span>
				<ng-container *ngFor="let selectedBoardItem of boardsSelectedItems.boardItems">
					<span class="number" [class.combo-bet]="selectedBoardItem.isComboBetPick">
						{{ selectedBoardItem.number }}
					</span>
				</ng-container>
			</ng-container>
			<small class="tier-label" *ngIf="!lineSelectedItems.isDefault">{{lineSelectedItems.tierName}}</small>
			<button class="remove-btn" (click)="remove(i)"></button>
		</li>
	</ul>

	<div class="empty-picker" *ngIf="ticketSelectedItems.length === 0">
		<span class="line-number">{{ (65) | numberToChar }}.</span>
		<ng-container *ngFor="let boardsItems of emptyPicker; let j = index">
			<span class="board-separator" *ngIf="j > 0"> + </span>
			<ng-container *ngFor="let boardItem of boardsItems">
				<span class="number empty"></span>
			</ng-container>
		</ng-container>
		<button class="remove-btn" disabled></button>
	</div>

	<div class="ticket-summary">
		<div>
			<b>{{ 'purchase-print.line-price-lbl' | translate }}</b> {{ linePrice | currency:currencyCode:'symbol':'1.0-2':localeID | hgReplaceCurrencyCode:currencyCode }}
		</div>
		<div *ngIf="combinations > ticketSelectedItems.length">
			<b>{{ 'purchase-print.line-number-lbl' | translate }}</b> {{ combinations }}
		</div>
		<div>
			<b>{{ 'purchase-print.ticket-validity-lbl' | translate }}</b> {{ 'draw-selector.values.draws' | translate:{ 'drawsAmount': consecutiveDraws } }}
		</div>
		<div>
			<b>{{ 'purchase-print.total-price-lbl' | translate }}</b> {{ ticketCost | currency:currencyCode:'symbol':'1.0-2':localeID | hgReplaceCurrencyCode:currencyCode }}
		</div>
		<div *ngIf="canShowMaxWin">
			<b>{{ 'purchase-print.max-win-lbl' | translate }}</b> {{ maxWin | currency:currencyCode:'symbol':'1.0-2':localeID | hgReplaceCurrencyCode:currencyCode }}
		</div>
	</div>

	<div class="mob-only">
		<p>
			<label>{{ 'purchase-print.num-of-draws-lbl' | translate }}</label>
			<hg-dropdown [list]="consecutiveDrawsItems" [listSelection]="selectedConsecutiveDraws"
				(listSelectionChange)="consecutiveDrawsChange($event)">
				<ng-template #selectedItemTemplate>
					<span>
						{{ 'draw-selector.values.draws' | translate:{ 'drawsAmount': selectedConsecutiveDraws[0].numOfDraws } }}
					</span>
				</ng-template>
				<ng-template #itemsTemplate let-item>
					<span>
						{{ 'draw-selector.values.draws' | translate:{ 'drawsAmount': item.numOfDraws } }}
					</span>
				</ng-template>
			</hg-dropdown>

			<hg-dropdown [list]="games" [listSelection]="selectedGames" (listSelectionChange)="selectedGameChange($event)" *ngIf="multiBetsAllowed">
				<ng-template #selectedItemTemplate>
					<span>
						{{selectedGames[0].tier}}
					</span>
				</ng-template>
				<ng-template #itemsTemplate let-item>
					<span>
						{{item.tier}}
					</span>
				</ng-template>
			</hg-dropdown>
		</p>
		<button class="default-btn" (click)="addRandom()">{{ 'purchase-print.add-random-btn' | translate }}</button>
		<button class="default-btn" (click)="pickNumbers()">{{ 'purchase-print.pick-nums-btn' | translate }}</button>
	</div>

	<div class="comment-container" *ngIf="betRequiresComment && !isQuickBuyReady">
		<div class="comment-label-container">
			<label for="comment" class="comment-label">{{ 'purchase-print.customer-name' | translate }}</label>
		</div>
		<div class="comment-input-container">
			<input (input)="onCommentUpdate($event.target.value)" id="comment" class="comment-input">
		</div>
	</div>

	<ra-buy-button #buyButton (bet)="onPurchaseAndPrintCallback($event)">
		<button class="default-btn" [class.is-loading]="isBetLoading" (click)="purchaseAndPrint()" [disabled]="ticketSelectedItems.length === 0 || (betRequiresComment && comment.length === 0)">
			<span>{{ 'purchase-print.purchase-and-print-btn' | translate }}</span>
		</button>
	</ra-buy-button>
</div>
