<div class="content-wrapper">
	<div class="cards-wrapper">
		<ng-container *ngIf="stats.length > 0 && !getDataError && !isLoading">
			<ra-stats-card *ngFor="let stat of stats" [agentStat]="stat"></ra-stats-card>
		</ng-container>

		<ng-container *ngIf="isLoading">
			<div class="loading">
				<div class="loader"></div>
			</div>
		</ng-container>
	</div>

	<ng-container *ngIf="getDataError">
		<h4>{{ 'sales-stats.get-data-error' | translate }}</h4>
	</ng-container>

	<ng-container *ngIf="stats.length === 0 && !getDataError && !isLoading">
		<h4>{{ 'sales-stats.no-data' | translate }}</h4>
	</ng-container>
</div>