<div class="comment-container">
    <span class="icon-close" (click)="cancel()"></span>
    <h3>{{ 'purchase-print.customer-name' | translate }}</h3>
    <div class="comment-input-container">
        <label for="comment"></label><input #commentInput (input)="onCommentUpdate($event.target.value)" id="comment" class="comment-input">		
    </div>	

    <button type="button" class="default-btn" [disabled]="!commentText || commentText.length === 0" (click)="buy()">
        <span>{{ 'purchase-print.purchase-and-print-btn' | translate }}</span>
    </button>
</div>