import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'ra-public-content',
	templateUrl: './public-content.component.html',
	styleUrls: ['./public-content.component.scss']
})
export class PublicContentComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
