<div class="overlay-content">
	<div class="overflow-content">
		<div id="ticket-to-print">
			<ra-print-content [couponToPrint]="couponToPrint"></ra-print-content>
		</div>
		<div class="footer add-margin">
			<button class="default-btn add-margin" *ngIf="canCancel" (click)="cancelTicketConfirm()" [disabled]="!canCancel">
				{{ 'scanned-ticket.cancel-ticket-btn' | translate }}
			</button>
			<br>
			<button [ngClass]="{ 'default-btn': !isPrinting || (canReprint && !hasClickedPrintBtn), 'secondary-btn': isPrinting || (!canReprint && hasClickedPrintBtn) }" [disabled]="isPrinting || (!canReprint && hasClickedPrintBtn)" (click)="print()">
				<span *ngIf="isPrinting">{{ 'purchase-print.printing' | translate }}</span>
				<span *ngIf="(!isPrinting && !canReprint) || (canReprint && !hasClickedPrintBtn)">{{ 'purchase-print.print-btn' | translate }}</span>
				<span *ngIf="!isPrinting && hasClickedPrintBtn && canReprint">{{ 'purchase-print.reprint-btn' | translate }}</span>
			</button>
			<button *ngIf="canShare" class="default-btn" (click)="share()">
				{{ 'purchase-print.share-btn' | translate }}
			</button>
			<button class="default-btn" (click)="closePrintTicket()" [disabled]="isPrinting || (!hasClickedPrintBtn && !hasClickedShareBtn)">
				{{ 'purchase-print.done-btn' | translate }}
			</button>
		</div>
	</div>
</div>
<div #printIframeDiv></div>