<div class="game-logo">
	<div class="logo {{ agentStat.gameGroupCode }}"></div>
</div>
<div class="info">
	<h2>{{ agentStat.gameName }}</h2>
	<div class="stats">
		<div class="sold">
			<b>{{ 'sales-stats.card.sold-lbl' | translate }}</b>
			{{ agentStat.totalSales | currency:currencyCode:'symbol':'1.0-2':localeID | hgReplaceCurrencyCode:currencyCode }}
		</div>
		<div class="paid-out">
			<b>{{ 'sales-stats.card.payouts-lbl' | translate }}</b>
			{{ agentStat.totalPayouts | currency:currencyCode:'symbol':'1.0-2':localeID | hgReplaceCurrencyCode:currencyCode }}
		</div>
		<div class="cancelled">
			<b>{{ 'sales-stats.card.cancelled-lbl' | translate }}</b>
			{{ agentStat.totalCancelled | currency:currencyCode:'symbol':'1.0-2':localeID | hgReplaceCurrencyCode:currencyCode }}
		</div>
	</div>
</div>
