<div class="top-header">
	<span>{{ now | date:'mediumDate':undefined:localeID }}, {{ now | date:'shortTime':localeID }}</span>
	<span>
		{{ agentFullName }}
		(ID: {{agentID}}, {{ 'app-header.balance' | translate }} {{ balance | currency:currencyCode:'symbol':'1.0-2':localeID }})
	</span>
</div>

<div class="header-wrapper">
	<div class="logo-wrapper">
		<div class="helio-logo"></div>
	</div>
	<div class="header-content">
		<ra-check-ticket></ra-check-ticket>		
		<div class="right">			
			<button *ngIf="paymentGateway !== undefined && idempotencyKey !== undefined" class="default-btn" (click)="deposit()">
				<span *ngIf="!depositing && !redirecting">{{ 'app-header.deposit-btn' | translate }}</span>
				<span *ngIf="depositing">{{ 'app-header.depositing' | translate }}</span>
				<span *ngIf="!depositing && redirecting">{{ 'app-header.redirecting' | translate }}</span>
			</button>
			<ra-language-select *ngIf="languages.length > 1"></ra-language-select>
			<button class="default-btn" (click)="logout()">{{ 'app-header.logout-btn' | translate }}</button>
		</div>
	</div>
</div>