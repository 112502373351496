<h1>{{ 'draw-stats.title' | translate }}</h1>
<h2>{{ gameName }}</h2>

<div class="table-wrapper">
	<ngx-datatable class="material"
		[rows]="draws"		
		[columnMode]="'force'"
		[footerHeight]="0"
		rowHeight="auto"
		[reorderable]="false"
		[externalPaging]="true"
		[count]="draws.length"
		[offset]="0"
		[limit]="draws.length"		
	>
		<ngx-datatable-column [width]="100" [sortable]="false" [resizeable]="false" [name]="'game-draw-stats.table.draw-num-short' | translate">
			<ng-template let-row="row" ngx-datatable-cell-template>
				{{ row['drawNumber'] }}
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-column [sortable]="false" [resizeable]="false" [name]="'game-draw-stats.table.draw-date-short' | translate">
			<ng-template let-row="row" ngx-datatable-cell-template>
				{{ row['drawDate'] | date:'short':undefined:localeID }}
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-column [sortable]="false" [resizeable]="false" [name]="'game-draw-stats.table.draw-result-short' | translate">
			<ng-template let-row="row" ngx-datatable-cell-template>
				{{ row['results'] | raFormatDrawResult }}
			</ng-template>
		</ngx-datatable-column>
	</ngx-datatable>
</div>