import {CurrencyPipe} from '@angular/common'
import {Pipe} from '@angular/core';

@Pipe({name: 'currencyWithSpace', pure: true})
export class CurrencyWithSpacePipe extends CurrencyPipe {

	constructor() {
		super(getLang());
	}

	// (value: any, currencyCode?: string, display?: string | boolean, digitsInfo?: string, locale?: string): string
	// @ts-ignore
	transform(
		value: number | string, currencyCode?: string,
		display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
		digitsInfo?: string, locale?: string): string | null {
		let renderedCurrency = super.transform(value, currencyCode, display, digitsInfo, locale);
		const testCurrency = super.transform(0, currencyCode, display, digitsInfo, locale);

		const code = testCurrency.substring(0, testCurrency.length - 1);

		if (currencyCode.length > 1) {
			renderedCurrency = renderedCurrency.replace(' ', '');

			renderedCurrency = renderedCurrency.indexOf(code) === 0
				? renderedCurrency.replace(code, `${code} `)
				: renderedCurrency.replace(code, ` ${code}`);
		}

		return renderedCurrency;
	}
}

export const getLang = () => {
	if (navigator.languages !== undefined) {
		return navigator.languages[0];
	}
	return navigator.language;
}
