import {Component, OnInit, Input, HostBinding} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {AppConfigService, GameFinancialRulesService, GameService} from 'helio-games-core';
import {PurchasedTicket} from '../print-ticket';
import {pageFontSize} from '../../../shared/utils';
import {AppLangService} from '../../../app-header';
import {AuthService} from '../../../shared/services';
import {BuyTicketService} from '../../buy-ticket.service';
import {CouponToPrint} from '../print-ticket/coupon.model';
import {CapabilitiesService} from 'src/app/shared/services/capabilities.service';
import QRCode from 'qrcode';

@Component({
	selector: 'ra-print-content',
	templateUrl: './print-content.component.html',
	styleUrls: ['./print-content.component.scss']
})
export class PrintContentComponent implements OnInit {

	@Input() couponToPrint: CouponToPrint | undefined;

	@HostBinding('style.padding') padding = '0';

	lotteryTickets: PurchasedTicket[];
	raffleTickets: PurchasedTicket[];

	totalCost = 0;

	isPrintPage = false;
	reprints = 0;

	afterPrintSubscription: Subscription;

	localeID: string;

	tenantLogo: string;
	agentFullName: string;
	agentAddress: string;
	termsAndConditions: string;

	hasTenantLogo = false;
	comment = '';

	canShowMaxWin = false;
	qrCodeDataURL: any;

	constructor(
		private route: ActivatedRoute,
		private authService: AuthService,
		private appLangService: AppLangService,
		private appConfigService: AppConfigService,
		private buyTicketService: BuyTicketService,
		private gameFinancialRulesService: GameFinancialRulesService,
		private capabilities: CapabilitiesService,
		private gameService: GameService
	) {
	}

	ngOnInit() {
		this.localeID = this.appLangService.selectedLocaleID;

		this.reprints = parseInt((localStorage.getItem('ticket-reprints') || '0'), 10);

		this.hasTenantLogo = this.authService.authTokenData.payload.Logo !== '' &&
			this.authService.authTokenData.payload.Logo !== undefined && this.authService.authTokenData.payload.Logo !== null;

		const logoBaseUrl = `${this.appConfigService.cdnBaseURL}assets/img/tenant-logos/`;
		this.tenantLogo = `url(${logoBaseUrl}${this.authService.authTokenData.payload.Logo})`;

		this.agentFullName = this.authService.authTokenData.payload.FullName;
		this.agentAddress = this.authService.authTokenData.payload.Address;

		this.comment = this.buyTicketService.comment;

		let termsAndConditions;

		// this.canShowMaxWin = this.capabilities.getCapability('showMaxWin');

		try {
			termsAndConditions = JSON.parse(this.authService.authTokenData.payload.TermsAndConditions);
		} catch (error) {
			console.log('Error - no terms or conditions were found.');
		}

		if (termsAndConditions && Object.keys(termsAndConditions).length > 0) {
			const selectedTermsAndConditions = termsAndConditions[this.appLangService.selectedLanguage.toLowerCase()].replace(/\+/g, ' ');
			this.termsAndConditions = decodeURIComponent(selectedTermsAndConditions);
		}

		this.buyTicketService.commentUpdate.subscribe(comment => {
			this.comment = comment;
		});

		if (this.couponToPrint === undefined) {
			this.route.paramMap.subscribe((params) => {
				const printData = params.get('printData');

				if (printData !== null) {
					this.isPrintPage = true;
					const jsonString = atob(printData);
					this.couponToPrint = JSON.parse(jsonString);

					this.totalCost = this.couponToPrint.tickets.reduce((total: number, t: PurchasedTicket) => {
						return total + t.ticketCost;
					}, 0);

					this.setStyle();
				}
			});
		} else {
			this.totalCost = this.couponToPrint.tickets.reduce((total: number, t: PurchasedTicket) => {
				return total + t.ticketCost;
			}, 0);

			this.raffleTickets = this.couponToPrint.tickets
				.filter(entry => entry.gameVertical === 'Raffle');

			this.lotteryTickets = this.couponToPrint.tickets
				.filter(entry => entry.gameVertical !== 'Raffle');
		}

		this.generateQRCode();
	}

	async generateQRCode() {
		try {
			this.qrCodeDataURL = await QRCode.toDataURL(this.couponToPrint.tickets[0].couponIdentifier, {
				errorCorrectionLevel: 'H',
				width: 150,
				height: 150
			});
		} catch (error) {
			console.error('Error generating QR code', error);
		}
	}

	private setStyle() {
		this.padding = '15px';

		const html = document.getElementsByTagName('html')[0];
		const body = document.getElementsByTagName('body')[0];

		const pageSize = (localStorage.getItem('agentAppPageSize') === null) ? 80 : parseInt(localStorage.getItem('agentAppPageSize'), 10);

		const fontSizeConfig = pageFontSize.item(pageSize);

		html.style.width = fontSizeConfig.pageWidthMM;
		html.style.fontSize = fontSizeConfig.fontSizePercentage;

		html.style.height = 'auto';
		body.style.height = 'auto';
	}
}
