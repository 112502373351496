import { Component, OnInit } from '@angular/core';
import { AppOverlaysService } from '../shared/services';
import { PrintTicketComponent } from '../buy-ticket/purchase-print';
import { pageFontSize, HtmlPageFontSize } from '../shared/utils';

@Component({
	selector: 'ra-printer-setup',
	templateUrl: './printer-setup.component.html',
	styleUrls: ['./printer-setup.component.scss']
})
export class PrinterSetupComponent implements OnInit {

	dropdownItems: HtmlPageFontSize[] = [];
	selectedItem: HtmlPageFontSize[] = [];

	constructor(
		private appOverlaysService: AppOverlaysService
	) { }

	ngOnInit() {
		this.dropdownItems = pageFontSize.values();

		const pageSize = (localStorage.getItem('agentAppPageSize') === null) ? '80mm' : localStorage.getItem('agentAppPageSize');

		const index = this.dropdownItems.findIndex(temp => temp.pageWidthMM === pageSize);
		this.selectedItem = [this.dropdownItems[index]];
	}

	dropdownChange(selectedPageSize: HtmlPageFontSize[]) {
		this.selectedItem = selectedPageSize;
		localStorage.setItem('agentAppPageSize', this.selectedItem[0].pageWidthMM);
	}

	testPrint() {
		this.appOverlaysService.loadOverlay(PrintTicketComponent);
	}

}
