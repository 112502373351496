import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class AppLayoutService {
	private mobileVersionChange = new Subject<boolean>();
	private isMobileVersion = false;

	getFontSize(windowWidth: number): number {
		const defaultFontSize = 62.5;

		if (windowWidth > 887) {
			return (windowWidth / 1920) * defaultFontSize;
		} else if (windowWidth <= 887 && windowWidth > 600) {
			return (windowWidth / 1920) * 87.5;
		} else if (windowWidth <= 600 && windowWidth > 425) {
			return (windowWidth / 1920) * 120;
		} else {
			return (windowWidth / 1920) * 175;
		}
	}

	get onMobileVersionChange(): Observable<boolean> {
		return this.mobileVersionChange.asObservable();
	}

	get isMobile() {
		return this.isMobileVersion;
	}

	set isMobile(isMobileVersion: boolean) {
		this.isMobileVersion = isMobileVersion;
		this.mobileVersionChange.next(isMobileVersion);
	}
}
