import { Component, OnInit } from '@angular/core';
import { appOverlaysAnimations } from '../app-overlays.animations';

@Component({
	selector: 'ra-app-loader',
	templateUrl: './app-loader.component.html',
	styleUrls: ['./app-loader.component.scss'],
	animations: [appOverlaysAnimations]
})
export class AppLoaderComponent implements OnInit {

	constructor() { }

	ngOnInit() {
	}

}
